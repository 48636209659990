<template>
  <div class="flex flex-col gap-8">
    <div name="series-based-pricing-input-group" class="flex">
      <div class="flex flex-1 flex-row flex-wrap gap-y-6">
        <div name="reference-product-01" class="w-1/2 pr-3">
          <label class="label pb-0 font-semibold">Reference Product 01</label>
          <div class="divider my-0"></div>
          <div class="flex gap-3">
            <PricingFormInput
              name="lengthProductOne"
              :label="`Length ${lengthUnitOfPartNumberToPrice}`"
              :disabled="isPricingInputsDisabled"
              type="number"
              :step="step"
              :min="0"
              :isValid="!hasSameReferenceProductsLength"
              errorMessage="Reference lengths cannot be same"
              v-model="saleLineToPrice.referenceProduct1Length"
              @input="onChangeCalculatingData"
            />
            <PricingFormInput
              name="priceProductOne"
              label="Price ($)"
              :disabled="isPricingInputsDisabled"
              type="number"
              :step="step"
              :min="0"
              v-model="saleLineToPrice.referenceProduct1Price"
              @input="onChangeCalculatingData"
            />
          </div>
        </div>
        <div name="reference-product-02" class="w-1/2 pl-3">
          <label class="label pb-0 font-semibold">Reference Product 02</label>
          <div class="divider my-0"></div>
          <div class="flex gap-3">
            <PricingFormInput
              name="lengthProductTwo"
              :label="`Length ${lengthUnitOfPartNumberToPrice}`"
              :disabled="isPricingInputsDisabled"
              type="number"
              :step="step"
              :min="0"
              :isValid="!hasSameReferenceProductsLength"
              errorMessage="Reference lengths cannot be same"
              v-model="saleLineToPrice.referenceProduct2Length"
              @input="onChangeCalculatingData"
            />
            <PricingFormInput
              name="priceProductTwo"
              label="Price ($)"
              :disabled="isPricingInputsDisabled"
              type="number"
              :step="step"
              :min="0"
              v-model="saleLineToPrice.referenceProduct2Price"
              @input="onChangeCalculatingData"
            />
          </div>
        </div>
        <div name="price-calculation" class="w-full">
          <label class="label pb-0 font-semibold">Price Calculation</label>
          <div class="divider my-0"></div>
          <div class="flex gap-3">
            <PricingFormInput
              name="cableToPriceLength"
              :label="`Quoted Product's Length ${lengthUnitOfPartNumberToPrice}`"
              :disabled="true"
              type="number"
              :step="step"
              :min="0"
              v-model="saleLineToPrice.quotedProductLength"
              @input="onChangePricingInput"
            />
            <PricingFormInput
              name="perFootAdder"
              :label="`Per Unit Adder ($)${lengthUnitOfPartNumberToPrice && '/'}${lengthUnitOfPartNumberToPrice}`"
              :disabled="true"
              type="number"
              :step="step"
              :min="0"
              v-model="perFootAdderComputed"
              @input="onChangePricingInput"
            />
            <PricingFormInput
              name="seriesBasedSuggestedPrice"
              label="Suggested Price ($)"
              :disabled="isPricingInputsDisabled"
              type="number"
              :step="step"
              :min="0"
              v-model="seriesBasedSuggestedPriceComputed"
              @input="onChangeSeriesBasedSuggestedPrice"
            />
          </div>
        </div>
      </div>

      <div class="divider divider-horizontal"></div>

      <div class="flex w-1/3 flex-col gap-y-5">
        <div name="notes" class="w-full">
          <label for="notes" class="label pb-0 font-semibold">Notes</label>
          <div class="divider mt-0"></div>
          <textarea
            id="notes"
            class="textarea textarea-bordered text-base border-2 w-full h-4/6 border-gray-200 focus:border-gray-300 bg-gray-50 disabled:bg-gray-100 rounded-md focus:outline-none"
            :disabled="isPricingInputsDisabled"
            placeholder="Type here..."
            v-model="saleLineToPrice.notes"
          ></textarea>
        </div>
        <div name="series-based-costing-information" class="w-full">
          <label class="label pb-0 font-semibold">Additional Costing Information</label>
          <div class="divider my-0"></div>
          <div class="flex gap-3">
            <PricingFormInput
              name="cableCost"
              label="Cable Cost Per Unit ($)"
              :disabled="isPricingInputsDisabled"
              type="number"
              :step="step"
              :min="0"
              v-model="saleLineToPrice.cableCost"
              @input="onChangeCalculatingData"
            />
            <PricingFormInput
              name="laborCost"
              label="Labor Cost ($)"
              :disabled="isPricingInputsDisabled"
              type="number"
              :step="step"
              :min="0"
              v-model="saleLineToPrice.laborCost"
              @input="onChangeCalculatingData"
            />
          </div>
          <div class="flex gap-3">
            <PricingFormInput
              name="otherCosts"
              label="Other Costs ($)"
              :disabled="isPricingInputsDisabled"
              type="number"
              :step="step"
              :min="0"
              v-model="saleLineToPrice.otherCosts"
              @input="onChangeCalculatingData"
            />
            <PricingFormInput
              name="odooCost"
              label="Odoo Costs ($)"
              :disabled="true"
              type="number"
              v-model="saleLineToPrice.erpCost"
            />
          </div>
          <br />
          <div class="flex gap-3 flex-row-reverse">
            <v-chip v-if="totalCost">Total cost: ${{ totalCost }}</v-chip>
          </div>
        </div>
      </div>
    </div>
    <div name="pricing-button-group" class="flex gap-2">
      <button
        :disabled="pricingForm.isLoading || !dataHasBeenChanged"
        class="btn btn-md btn-primary rounded-sm w-24 min-h-fit h-10 text-white"
        @click="onPricingFormEvents.submit"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import PricingFormInput from '@/components/views/simulator/PricingFormInput.vue';
import { ref, computed, reactive } from 'vue';
import { updateSaleLine } from '@/services/pricingHistories.service';
import { dispatchToast } from '@/utils/dispatchToast';
import EventTracker from '@/utils/EventTracker';
import { watch } from 'vue';

export default {
  name: 'SeriesBasedForm',
  components: {
    PricingFormInput,
  },
  props: {
    totalCost: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup() {
    const store = useStore();
    const saleLineToPrice = computed(() => store.getters['simulator/saleLineToPrice']);

    const step = '0.01';

    const initialStates = {
      pricingForm: {
        isLoading: false,
        error: '',
      },
      seriesBasedPricingData: {
        lengthProductOne: null,
        priceProductOne: null,
        lengthProductTwo: null,
        priceProductTwo: null,
        cableToPriceLength: null,
        perFootAdder: null,
      },
    };

    const pricingForm = reactive(initialStates.pricingForm);
    const seriesBasedPricingData = reactive(initialStates.seriesBasedPricingData);
    const seriesBasedSuggestedPrice = ref(null);
    const dataHasBeenChanged = ref(false);

    /**
     * ===========================================================
     * ========================= Computed ========================
     * ===========================================================
     */
    const isPricingInputsDisabled = computed(() => pricingForm.isLoading);

    const lengthUnitOfPartNumberToPrice = computed(() => {
      const unit = saleLineToPrice.value.lengthInfo.unit;
      let lengthUnit = '';

      if (unit === 'F') {
        lengthUnit = '(Feet)';
      } else if (unit === 'M') {
        lengthUnit = '(Meters)';
      } else if (unit === 'I') {
        lengthUnit = '(Inches)';
      } else if (unit === 'CM') {
        lengthUnit = '(Centimeters)';
      }

      return lengthUnit;
    });

    const hasSameReferenceProductsLength = computed(() => {
      let response = false;

      const lengthProductOne = saleLineToPrice.value.referenceProduct1Length;
      const lengthProductTwo = saleLineToPrice.value.referenceProduct2Length;

      if (lengthProductOne && lengthProductTwo) {
        response = Number(lengthProductOne) === Number(lengthProductTwo);
      }

      return response;
    });
    const isValidCostInput = computed(() => {
      return (
        !isNaN(saleLineToPrice.value.quotedProductLength) &&
        !isNaN(saleLineToPrice.value.cableCost) &&
        saleLineToPrice.value.quotedProductLength !== null &&
        saleLineToPrice.value.cableCost !== null
      );
    });
    // ========================= PerFootAdder Calculation =========================
    const perFootAdderCalculated = computed(() => {
      const lengthProductOne = saleLineToPrice.value.referenceProduct1Length;
      const priceProductOne = saleLineToPrice.value.referenceProduct1Price;
      const lengthProductTwo = saleLineToPrice.value.referenceProduct2Length;
      const priceProductTwo = saleLineToPrice.value.referenceProduct2Price;

      const hasDataForCalculations = lengthProductOne && priceProductOne && lengthProductTwo && priceProductTwo;

      // Per Foot Adder  = |(Product 1 Price  - Product  2 Price) / (Product 1 Length- Product 2 Length)|
      let perFootAdder = null;
      if (hasDataForCalculations) {
        const differenceInPrice = Number(priceProductOne) - Number(priceProductTwo);
        const differenceInLengths = Number(lengthProductOne) - Number(lengthProductTwo);

        perFootAdder = Math.abs(differenceInPrice / (differenceInLengths || 1)).toFixed(6);
      }

      return perFootAdder;
    });

    const perFootAdderComputed = computed(() => {
      let perFootAdder = null;

      if (saleLineToPrice.value.perFootAdder !== null) {
        perFootAdder = saleLineToPrice.value.perFootAdder;
      }
      if (perFootAdderCalculated.value !== null) {
        perFootAdder = perFootAdderCalculated.value;
      }
      if (seriesBasedPricingData.perFootAdder !== null) {
        perFootAdder = seriesBasedPricingData.perFootAdder;
      }

      return perFootAdder?.toString();
    });

    // ========================= Series Based Suggested Price Calculation =========================
    const seriesBasedSuggestedPriceCalculated = computed(() => {
      const lengthProductOne = saleLineToPrice.value.referenceProduct1Length;
      const priceProductOne = saleLineToPrice.value.referenceProduct1Price;

      const hasDataForCalculations =
        lengthProductOne && priceProductOne && perFootAdderComputed.value && saleLineToPrice.value.quotedProductLength;

      // Series Based Suggested Price Formula = Product 1 Price + ({ Product to Price Length - Product 1 Length } * Per Foot Adder)
      let calculatedSuggestedPrice = null;
      if (hasDataForCalculations) {
        const differenceInLengths = Number(saleLineToPrice.value.quotedProductLength) - Number(lengthProductOne);

        calculatedSuggestedPrice = Math.abs(
          Number(priceProductOne) + Number(differenceInLengths * perFootAdderComputed.value),
        ).toFixed(2);
      }

      return calculatedSuggestedPrice;
    });
    const seriesBasedSuggestedPriceComputed = computed(() => {
      let computedSuggestedPrice = null;

      if (saleLineToPrice.value.pricingType === 'Series Based') {
        computedSuggestedPrice = saleLineToPrice.value.suggestedPrice;
      }
      if (seriesBasedSuggestedPriceCalculated.value !== null) {
        computedSuggestedPrice = seriesBasedSuggestedPriceCalculated.value;
      }
      if (saleLineToPrice.value.suggestedPrice !== null && !dataHasBeenChanged.value) {
        computedSuggestedPrice = saleLineToPrice.value.suggestedPrice;
      }
      return computedSuggestedPrice?.toString();
    });

    /**
     * ===========================================================
     * ========================= Methods =========================
     * ===========================================================
     */

    const onChangePricingInput = (e) => {
      seriesBasedPricingData[e.target.name] = e.target.value;
      dataHasBeenChanged.value = true;
    };

    const onChangeCalculatingData = (e) => {
      seriesBasedPricingData[e.target.name] = e.target.value;
      dataHasBeenChanged.value = true;
    };

    const onChangeSeriesBasedSuggestedPrice = (e) => {
      seriesBasedSuggestedPrice.value = e.target.value;
      saleLineToPrice.value = e.target.value;
      dataHasBeenChanged.value = true;
    };

    let previousState = JSON.parse(JSON.stringify(saleLineToPrice.value));

    watch(
      saleLineToPrice.value,
      (newVal) => {
        if (
          newVal.referenceProduct1 !== previousState.referenceProduct1 ||
          newVal.referenceProduct2 !== previousState.referenceProduct2
        ) {
          dataHasBeenChanged.value = true;
        }
        // Update the previous state with the current state
        previousState = JSON.parse(JSON.stringify(newVal));
      },
      { deep: true },
    );
    /**
     * ===========================================================
     * =================== Form Events Handlers ==================
     * ===========================================================
     */
    const onPricingFormEvents = {
      async submit() {
        pricingForm.isLoading = true;

        EventTracker.calculatorSubmit();

        const isPriceSuggested = seriesBasedSuggestedPriceComputed.value;

        const suggestedPrice = seriesBasedSuggestedPrice.value || isPriceSuggested;

        const reqBody = {
          pricingType: isPriceSuggested ? 'Series Based' : null,
          expectedMargin: null,
          perFootAdder: perFootAdderComputed.value || null,
          cableCost: saleLineToPrice.value.cableCost || null,
          laborCost: saleLineToPrice.value.laborCost || null,
          otherCosts: saleLineToPrice.value.otherCosts || null,
          suggestedPrice,
          notes: saleLineToPrice.value.notes || '',
          quotedProductLength: saleLineToPrice.value.quotedProductLength || null,
          referenceProduct1: saleLineToPrice.value.referenceProduct1 || null,
          referenceProduct1Length: saleLineToPrice.value.referenceProduct1Length || null,
          referenceProduct1Price: saleLineToPrice.value.referenceProduct1Price || null,
          referenceProduct2: saleLineToPrice.value.referenceProduct2 || null,
          referenceProduct2Length: saleLineToPrice.value.referenceProduct2Length || null,
          referenceProduct2Price: saleLineToPrice.value.referenceProduct2Price || null,
        };

        const { success, data } = await updateSaleLine(saleLineToPrice.value.id, reqBody);

        if (!success) {
          pricingForm.error = data.message;
          dispatchToast('Unable to save the pricing information!', {
            type: 'error',
          });
        } else {
          store.commit('simulator/setSaleLineToPrice', {
            ...saleLineToPrice.value,
            ...data,
          });
          dispatchToast('Pricing Saved Successfully!', {
            type: 'success',
          });
          dataHasBeenChanged.value = false;
        }

        pricingForm.isLoading = false;
      },
    };

    return {
      step,
      pricingForm,
      isPricingInputsDisabled,
      saleLineToPrice,
      perFootAdderComputed,
      seriesBasedSuggestedPriceComputed,
      lengthUnitOfPartNumberToPrice,
      hasSameReferenceProductsLength,
      seriesBasedPricingData,
      onPricingFormEvents,
      onChangePricingInput,
      onChangeSeriesBasedSuggestedPrice,
      onChangeCalculatingData,
      dataHasBeenChanged,
      seriesBasedSuggestedPrice,
    };
  },
};
</script>
