import { post } from '@/utils/apiClient';

export const sendSalesOrderLinesToOdoo = async (saleOrderLines: SaleOrderLine[], saleOrderId: string) => {
  const apiResponse = await post(`/sale-orders/lines`, {
    saleOrderLines,
    saleOrderId,
  });
  return apiResponse;
};

interface SaleOrderLine {
  customerId: string;
  id: string;
  labor: number;
  materialMargin: number;
  notes: string;
  otherCosts: number;
  partNumber: string;
  perFootAdder: number;
  pricingType: string;
  productMargin: number;
  suggestedPrice: number;
  totalCableCost: number;
  uomQty: number;
}
