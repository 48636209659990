<template>
  <div class="w-[350px] flex flex-col justify-center items-center text-neutral mr-3 text-sm bg-gray-200 rounded-md">
    <div class="header mb-2 font-semibold">
      <p>{{ title }} - {{ customer }}</p>
    </div>
    <div v-if="!Object.keys(saleLineData).length" class="font-semibold text-red-500">
      <p>Not Found!</p>
    </div>
    <div v-else class="flex flex-col items-center gap-y-1">
      <p class="font-semibold text-center w-[330px] whitespace-nowrap overflow-hidden text-ellipsis">
        {{ saleLineData.customerDefaultCode }}
      </p>
      <p>{{ saleLineData.orderDate }}</p>
      <p>$ {{ saleLineData.unitPrice }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KPITile',
  props: {
    title: {
      type: String,
      default: '',
    },
    customer: {
      type: String,
      default: '',
    },
    saleLineData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>
