import { DynamicDataObject } from '@/types/shared.types';
import { get } from '@/utils/apiClient';

export const databaseSyncHourly: DatabaseSyncHourly = async () => {
  const { success, data } = await get(`/database-sync/hourly`);

  return { success, data };
};

interface DatabaseSyncHourly {
  (): Promise<{
    success: boolean;
    data: DynamicDataObject;
  }>;
}
