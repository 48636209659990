import axios, { AxiosRequestHeaders } from 'axios';
import config from '@/config';
import { auth0 } from '@/main';

import { isRequestToTheServer } from './helpers';

// ============================================= Interfaces =============================================
interface Get {
  (
    url: string,
    config?: {
      headers?: object;
      params?: object;
    },
    hasAuth?: boolean,
  ): Promise<{ success: boolean; data: any }>;
}

interface Del {
  (
    url: string,
    config?: {
      headers?: object;
      params?: object;
    },
    hasAuth?: boolean,
  ): Promise<{ success: boolean; data: any }>;
}

interface Post {
  (
    url: string,
    reqBody: object,
    config?: {
      headers?: object;
      params?: object;
    },
    hasAuth?: boolean,
  ): Promise<{ success: boolean; data: any }>;
}

interface Put {
  (
    url: string,
    reqBody: object,
    config?: {
      headers?: object;
      params?: object;
    },
    hasAuth?: boolean,
  ): Promise<{ success: boolean; data: any }>;
}
interface Patch {
  (
    url: string,
    reqBody: object,
    config?: {
      headers?: object;
      params?: object;
    },
    hasAuth?: boolean,
  ): Promise<{ success: boolean; data: any }>;
}
// ============================================= Interfaces =============================================

// ========================================== Helper functions ==========================================
async function getToken() {
  const token = await auth0.getAccessTokenSilently();
  return token;
}

const axiosApi = axios.create({
  baseURL: config.baseUrls.api || 'http://localhost:5003/api/v1',
});

export const get: Get = async function (url, config = {}, hasAuth = true) {
  try {
    if (hasAuth) {
      const token = await getToken();
      config = {
        ...config,
        headers: {
          ...config.headers,
          authorization: `Bearer ${token}`,
        },
      };
    }
    const { data } = await axiosApi.get(url, {
      ...config,
    } as AxiosRequestHeaders);

    if (isRequestToTheServer(data)) {
      return data;
    }
    return { success: true, data };
  } catch (error) {
    if (isRequestToTheServer(error)) {
      return error;
    }
    return { success: false, data: error };
  }
};
export const post: Post = async function (url, reqBody, config = {}, hasAuth = true) {
  try {
    if (hasAuth) {
      const token = await getToken();
      config = {
        ...config,
        headers: {
          ...config.headers,
          authorization: `Bearer ${token}`,
        },
      };
    }
    const { data } = await axiosApi.post(url, { ...reqBody }, {
      ...config,
    } as AxiosRequestHeaders);
    if (isRequestToTheServer(data)) {
      return data;
    }
    return { success: true, data };
  } catch (error) {
    if (isRequestToTheServer(error)) {
      return error;
    }
    return { success: false, data: error };
  }
};

export const postFormData: Post = async function (url, formData, config = {}, hasAuth = true) {
  try {
    if (hasAuth) {
      const token = await getToken();
      config = {
        ...config,
        headers: {
          ...config.headers,
          authorization: `Bearer ${token}`,
        },
      };
    }
    const { data } = await axiosApi.post(url, formData, {
      ...config,
    } as AxiosRequestHeaders);
    if (isRequestToTheServer(data)) {
      return data;
    }
    return { success: true, data };
  } catch (error) {
    if (isRequestToTheServer(error)) {
      return error;
    }
    return { success: false, data: error };
  }
};

export const put: Put = async function (url, reqBody, config = {}, hasAuth = true) {
  try {
    if (hasAuth) {
      const token = await getToken();
      config = {
        ...config,
        headers: {
          ...config.headers,
          authorization: `Bearer ${token}`,
        },
      };
    }
    const { data } = await axiosApi.put(url, { ...reqBody }, {
      ...config,
    } as AxiosRequestHeaders);

    if (isRequestToTheServer(data)) {
      return data;
    }
    return { success: true, data };
  } catch (error) {
    if (isRequestToTheServer(error)) {
      return error;
    }
    return { success: false, data: error };
  }
};

export const del: Del = async function (url, config = {}, hasAuth = true) {
  try {
    if (hasAuth) {
      const token = await getToken();
      config = {
        ...config,
        headers: {
          ...config.headers,
          authorization: `Bearer ${token}`,
        },
      };
    }
    const { data } = await axiosApi.delete(url, {
      ...config,
    } as AxiosRequestHeaders);

    if (isRequestToTheServer(data)) {
      return data;
    }
    return { success: true, data };
  } catch (error) {
    if (isRequestToTheServer(error)) {
      return error;
    }
    return { success: false, data: error };
  }
};

export const patch: Patch = async function (url, reqBody, config = {}, hasAuth = true) {
  try {
    if (hasAuth) {
      const token = await getToken();
      config = {
        ...config,
        headers: {
          ...config.headers,
          authorization: `Bearer ${token}`,
        },
      };
    }

    const { data } = await axiosApi.patch(url, { ...reqBody }, {
      ...config,
    } as AxiosRequestHeaders);

    if (isRequestToTheServer(data)) {
      return data;
    }
    return { success: true, data };
  } catch (error) {
    if (isRequestToTheServer(error)) {
      return error;
    }
    return { success: false, data: error };
  }
};
