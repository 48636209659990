
import { updateSaleLine } from '@/services/pricingHistories.service';
import VuetifyDataTable from '@/components/VuetifyDataTable.vue';
import AppSelect from '@/components/AppSelect.vue';
import {
  searchInSimulator,
  getTotalCost,
  getMaterialMargin,
  getProductMargin,
  canCalculateMargins,
  convertCamelCaseToTitleCase,
  createSaleLineDetails,
} from '@/utils/helpers';
import * as salesOrderLinesServices from '@/services/salesOrderLines.service';
import * as salesServices from '@/services/salesOrder.service';
import { onMounted, ref, computed, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { dispatchToast } from '@/utils/dispatchToast';
import { useStore } from 'vuex';
import EventTracker from '@/utils/EventTracker';
import ConfirmationModal from '@/components/ConfirmationModal.vue';
import config from '@/config';

export default {
  name: 'SaleOrderDetailsView',
  components: {
    VuetifyDataTable,
    AppSelect,
    ConfirmationModal,
  },
  methods: {
    openLink() {
      window.open(this.saleOrderLinesData.saleOrder.url, '_blank');
    },
    handleUpdate({ columnKey, value, item, data }) {
      EventTracker.cellEdit(this.saleOrderLinesData.saleOrder.name, item.partNumber, columnKey);
      // Search the edited item in the rowsData array by id
      const rowUpdated = this.saleOrderLinesData.data.rowsData.find((row) => row.id === item.id);
      // Update the respective item's value
      rowUpdated[columnKey] = value;

      // If we update the suggested price column then we have to recalculate the engine price
      if (columnKey === 'suggestedPrice') {
        this.onSaleLinesUpdate();
      }

      rowUpdated.totalCableCost = getTotalCost(
        data.cableCost,
        data.quotedProductLength,
        rowUpdated.labor,
        rowUpdated.otherCosts,
      );

      if (
        canCalculateMargins(
          rowUpdated.suggestedPrice || rowUpdated.enginePrice,
          rowUpdated.totalCableCost,
          rowUpdated.laborCost,
          rowUpdated.otherCosts,
        )
      ) {
        const materialMargin = getMaterialMargin(
          Number(rowUpdated.suggestedPrice || rowUpdated.enginePrice),
          rowUpdated.totalCableCost ? Number(rowUpdated.totalCableCost) : 0,
          Number(rowUpdated.otherCosts || 0),
        );
        const productMargin = getProductMargin(
          Number(rowUpdated.suggestedPrice || rowUpdated.enginePrice),
          rowUpdated.totalCableCost ? Number(rowUpdated.totalCableCost) : 0,
          Number(rowUpdated.otherCosts || 0),
          Number(rowUpdated.labor || 0),
        );
        rowUpdated.materialMargin = materialMargin;
        rowUpdated.productMargin = productMargin;
      } else {
        rowUpdated.materialMargin = null;
        rowUpdated.productMargin = null;
      }
    },
    async wrappedUpdateSaleLine(id, body) {
      const bodyCopy = { ...body };
      bodyCopy.laborCost = bodyCopy.labor;
      delete bodyCopy.labor;
      return await updateSaleLine(id, bodyCopy);
    },
  },
  computed: {
    showConfirmationModalComputed() {
      return this.showConfirmationModal;
    },
  },
  data() {
    return {
      confirmationModalMessage: 'Posting will overwrite the prices of the sales order lines in Odoo',
      confirmationModalTitle: 'Are you sure you want to post prices to Odoo?',
      confirmationModalConfirmButtonText: 'Post to Odoo',
      confirmationModalCancelButtonText: 'Continue Pricing',
    };
  },
  setup() {
    const route = useRoute();
    const store = useStore();

    const saleOrderId: string = route.params.saleOrderId as string;

    const initialStates = {
      notes: {
        isEditing: false,
        isLoading: false,
        error: '',
      },
    };

    const saleOrderStatesSelectOptions = reactive({
      isLoading: false,
      states: [],
    });

    const saleOrderPricingStatus = ref(null);
    const saleOrderLinesData: any = reactive({
      isLoading: false,
      error: '',
      data: {},
      saleOrder: {},
      customer: {},
    });

    const notes = reactive(initialStates.notes);

    const showConfirmationModal = ref(false);

    const isSaleLinesLoading = ref(false);

    // ========================= Lifecycle hooks =========================
    onMounted(async () => {
      saleOrderLinesData.isLoading = true;
      EventTracker.saleOrderView();
      await getSaleOrderStates();
      await getSaleLinesBySaleOrderId();

      if (saleOrderLinesData.saleOrder.pricingStatus && saleOrderLinesData.saleOrder.pricingStatus !== 'Posted') {
        await onSaleLinesUpdate();
      }
      saleOrderLinesData.isLoading = false;
    });

    // ========================= Computed =========================
    const isLoadingComputed = computed(() => saleOrderStatesSelectOptions.isLoading || saleOrderLinesData.isLoading);

    // ========================= Methods =========================
    const onNotesEvents = {
      cancel() {
        notes.isEditing = false;
      },
      async submit() {
        notes.isLoading = true;
        const reqBody = { notes: saleOrderLinesData.saleOrder.notes };
        const { success, data } = await salesServices.updateSaleOrder(saleOrderId, reqBody);
        if (!success) {
          notes.error = data;
        } else {
          saleOrderLinesData.saleOrder.notes = data.notes;
        }
        notes.isLoading = false;
        notes.isEditing = false;
      },
      edit() {
        notes.isEditing = true;
      },
    };

    const onSaleLinesUpdate = async () => {
      isSaleLinesLoading.value = true;

      const { success, data } = await salesServices.syncSalesOrderLinesBySaleOrderName(saleOrderLinesData.saleOrder.name);

      if (!success) {
        dispatchToast(data.message, {
          type: 'error',
          timeout: false,
          draggable: false,
          closeOnClick: false,
        });
      } else {
        // Select the same rows that appears in the responseData.saleLinesToBeCreatedOrUpdated
        const rowsToBeUpdated = saleOrderLinesData.data.rowsData?.filter((row) => {
          return data.saleLinesToBeCreatedOrUpdated.some((saleLine) => saleLine.id === row.erpId);
        });
        // Compare the previous rows with the new rows. When the previous engine price is different from the new one, then highlight the row (it is not done yet)
        const rowsToBeHighlighted = rowsToBeUpdated?.filter((row) => {
          const saleLine = data.saleLinesToBeCreatedOrUpdated.find((saleLine) => saleLine.id === row.erpId);
          return saleLine.enginePrice && row.enginePrice !== saleLine.enginePrice?.toFixed(6);
        });
        dispatchToast(`Sale Lines Updated Successfully! ${rowsToBeHighlighted?.length ?? 0} prices updated`, {
          type: 'success',
        });
        await getSaleLinesBySaleOrderId();
      }

      EventTracker.rfqLoad(true);

      isSaleLinesLoading.value = false;
    };

    const onConfirmationModalEvents = {
      async confirm() {
        const { success, data } = await salesOrderLinesServices.sendSalesOrderLinesToOdoo(
          saleOrderLinesData.data.rowsData,
          saleOrderLinesData.saleOrder.id,
        );
        EventTracker.postPrices(saleOrderLinesData.saleOrder.name);
        if (success) {
          dispatchToast('Prices posted to Odoo!', { type: 'success' });
          await getSaleLinesBySaleOrderId();
        } else {
          dispatchToast('Unable to post prices to Odoo!', { type: 'error' });
        }
        showConfirmationModal.value = false;
      },
      cancel() {
        showConfirmationModal.value = false;
      },
    };

    const getSaleOrderStates = async () => {
      saleOrderStatesSelectOptions.isLoading = true;
      const { success, data } = await salesServices.getSaleOrderStates();
      if (success) {
        saleOrderStatesSelectOptions.states = data.map((state) => {
          return { name: state, value: state };
        });
      } else {
        dispatchToast('Unable to get the Sale Order States!', {
          type: 'error',
        });
      }
      saleOrderStatesSelectOptions.isLoading = false;
    };

    const getSaleLinesBySaleOrderId = async () => {
      const { success, data } = await salesServices.getSaleOrderDetails(saleOrderId);

      if (!success) {
        saleOrderLinesData.error = data.message;
      } else {
        const saleOrder = data;
        saleOrderLinesData.data = formatSaleLineDetailsForVuetifyDataTable(saleOrder.saleLines);

        saleOrderPricingStatus.value = saleOrder.pricingStatus;
        saleOrderLinesData.saleOrder = saleOrder;
        saleOrderLinesData.saleOrder.url = `${config.baseUrls.erp}/web?#id=${saleOrder.erpId}&action=484&model=sale.order&view_type=form&cids=1&menu_id=300`;
        saleOrderLinesData.customer = saleOrder.partner;
      }
    };

    const onCellClick = (event) => {
      if (event?.colDef?.field === 'partNumber') {
        EventTracker.orderLineClick({
          partNumber: event?.data?.partNumber,
          customer: saleOrderLinesData.customer.name,
        });
        searchInSimulator(event?.data?.partNumber, saleOrderLinesData.customer.id);
        store.dispatch('simulator/saleLineToPrice', {
          saleOrderId,
          saleLineToPriceId: event?.data?.id,
        });
      }
    };

    const onChangePricingStatus = async (event) => {
      const newPricingStatus = event.target.value;
      saleOrderPricingStatus.value = newPricingStatus;
      const { success, data } = await salesServices.updateSaleOrder(saleOrderId, {
        pricingStatus: newPricingStatus,
      });
      if (!success) {
        dispatchToast(data.message, { type: 'error' });
      }
    };

    const formatSaleLineDetailsForVuetifyDataTable = (records: any[]) => {
      if (!records.length) return {};

      const columnsToShow = [
        'partNumber',
        'familyCode',
        'uomQty',
        'suggestedPrice',
        'odooCost',
        'totalCost',
        'materialMargin',
        'productMargin',
        'notes',
      ];

      const formattedTable: FormattedSaleLineDetailsDataForVuetifyDataTable = {
        columnsDefs: [],
        rowsData: [],
      };

      function createVuetifyColumnDef(colName: string): VuetifyDataTableHeader {
        const currentColDef: VuetifyDataTableHeader = {
          title: convertCamelCaseToTitleCase(colName),
          key: colName,
        };
        switch (colName) {
          case 'partNumber':
            currentColDef['width'] = 380;
            break;
          case 'familyCode':
            currentColDef['cellClass'] = 'hidden';
            break;
          case 'suggestedPrice':
            currentColDef['formatter'] = 'currency';
            currentColDef['fallbackColumn'] = 'enginePrice';
            currentColDef['fallbackTooltip'] = 'enginePriceTooltip';
            currentColDef['width'] = 140;
            break;
          case 'materialMargin':
          case 'productMargin':
            currentColDef['formatter'] = 'percentage';
            break;
          case 'uomQty':
            currentColDef['width'] = 80;
            break;
          case 'notes':
            currentColDef['width'] = 310;
            currentColDef['sortable'] = false;
            break;
          case 'totalCost':
            currentColDef['formatter'] = 'currency';
            currentColDef['width'] = 140;
            break;
        }
        return currentColDef;
      }

      const columnsDefs: VuetifyDataTableHeader[] = [];
      columnsToShow.forEach((colName: string) => {
        const currentColDef: VuetifyDataTableHeader = createVuetifyColumnDef(colName);
        columnsDefs.push(currentColDef);
      });

      // Format Rows
      const rowsData: SaleLineDetailsForDataTable[] = [];

      records.forEach((record: any) => {
        const row: SaleLineDetailsForDataTable = createSaleLineDetails(record);
        rowsData.push(row);
      });

      formattedTable['columnsDefs'] = columnsDefs;
      formattedTable['rowsData'] = rowsData;

      return formattedTable;
    };

    return {
      showConfirmationModal,
      saleOrderPricingStatus,
      isLoadingComputed,
      saleOrderLinesData,
      saleOrderStatesSelectOptions,
      notes,
      isSaleLinesLoading,
      onNotesEvents,
      onConfirmationModalEvents,
      onCellClick,
      onChangePricingStatus,
      onSaleLinesUpdate,
      formatSaleLineDetailsForVuetifyDataTable,
    };
  },
};

interface FormattedSaleLineDetailsDataForVuetifyDataTable {
  columnsDefs: VuetifyDataTableHeader[];
  rowsData: SaleLineDetailsForDataTable[];
}

interface SaleLineDetailsForDataTable {
  partNumber: string;
  partNumberBase: string;
  familyCode: string;
  uomQty: string;
  pricingType: string;
  materialMargin: number | null;
  productMargin: number | null;
  suggestedPrice: string;
  enginePrice: string | null;
  enginePriceTooltip: string | null;
  odooCost: string;
  notes: string;
  customerId: string;
  id: string;
  erpId: string;
  isHigherLengthThanEverSold: boolean;
}

interface VuetifyDataTableHeader {
  key: string;
  title: string;
  colspan?: number;
  rowspan?: number;
  fixed?: boolean;
  align?: 'start' | 'end';
  width?: number;
  minWidth?: string;
  maxWidth?: string;
  sortable?: boolean;
  cellClass?: string | string[];
  formatter?: string;
  fallbackColumn?: string;
  fallbackTooltip?: string;
}

export type OdooStatus =
  | 'cancel'
  | 'done'
  | 'draft'
  | 'draft_quote'
  | 'inv'
  | 'lost'
  | 'partial'
  | 'pi'
  | 'sale'
  | 'sent'
  | 'shipped';
