const saleOrdersMutations = {
  setIsSearched(state: any, value: any) {
    state.isSearched = value;
  },
  setSaleOrders(state: any, value: any) {
    Object.assign(state.saleOrders, value);
  },
};

export default saleOrdersMutations;
