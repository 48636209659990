<template>
  <div class="toast-content">
    <span>{{ message }}</span>
    <i class="mdi mdi-content-copy ml-1" @click.stop="copyToClipboard" v-if="partNumber"></i>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    partNumber: {
      type: String,
      required: false,
    },
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.partNumber).then(() => {
        this.$emit('copied');
      });
    },
  },
};
</script>

<style scoped>
.toast-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ml-1 {
  margin-left: 10px;
  cursor: pointer;
}
</style>
