<template>
  <span>
    <v-icon v-if="isPricingVisible" @click="setReference('referenceProduct1')" icon="mdi-home-floor-1"> </v-icon>
    <v-icon
      v-if="isPricingVisible && !isCostBasedPricing"
      @click="setReference('referenceProduct2')"
      icon="mdi-home-floor-2"
    >
    </v-icon>
    {{ partNumber }}
  </span>
</template>

<script>
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { computed } from '@vue/reactivity';
import { convertLength } from '@/utils/unitConversion.ts';

export default {
  setup(props) {
    const store = useStore();
    const route = useRoute();

    const partNumber = computed(() => props.params?.value);
    const isPricingVisible = computed(
      () => Object.keys(store.getters['simulator/saleLineToPrice']).length > 0 && route.name !== 'history',
    );
    const isCostBasedPricing = computed(() => store.getters['simulator/saleLineToPrice'].pricingType === 'Cost Based');
    const convertProductLength = (length, fromUnit, toUnit) => {
      if (length && fromUnit && toUnit) {
        return Math.round(convertLength(length, fromUnit, toUnit) * 100) / 100;
      }
      return length;
    };

    const setReference = (referenceType) => {
      if (props.params?.data?.lengthData) {
        const convertedLength = convertProductLength(
          props.params?.data?.lengthData.length,
          props.params?.data?.lengthData.unit,
          store.getters['simulator/saleLineToPrice'].lengthInfo.unit,
        );

        store.getters['simulator/saleLineToPrice'][`${referenceType}Length`] = convertedLength;
        store.getters['simulator/saleLineToPrice'][`${referenceType}Price`] = props.params?.data?.priceUnit;
        store.getters['simulator/saleLineToPrice'][referenceType] = props.params?.data?.id;
      }
    };

    return {
      partNumber,
      isCostBasedPricing,
      isPricingVisible,
      setReference,
    };
  },
};
</script>

<style scoped></style>
