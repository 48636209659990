export const updateMixin = {
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    };
  },

  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', this.updateAvailable, {
      once: true,
    });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },

  methods: {
    async updateAvailable(event) {
      try {
        console.log('Updated event Received!');
        this.registration = event.detail;
      } catch (error) {
        console.log('Error Deleting the Cache', error);
      } finally {
        this.updateExists = true;
      }
    },

    // Called when the user accepts the update
    refreshApp() {
      this.updateExists = false;

      if (!this.registration || !this.registration?.waiting) return;

      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      console.log('Reload!');
    },
  },
};
