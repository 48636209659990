export const twoDecimal = {
  beforeMount(el: any) {
    const formatValue = (fromEvent = false) => {
      const value = parseFloat(el.value);
      if (!isNaN(value)) {
        el.value = value.toFixed(2);
        // Only dispatch the event if this function isn't being called due to the blur event
        if (!fromEvent) {
          el.dispatchEvent(new Event('input'));
        }
      }
    };

    // Format the value when the directive is first bound
    formatValue();

    // Listen for blur changes to format the value, pass true to denote this call is from the event
    el.addEventListener('blur', () => formatValue(true));
  },
};
