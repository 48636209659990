const saleOrdersGetters = {
  isSearched(state: any) {
    return state.isSearched;
  },
  saleOrders(state: any) {
    return state.saleOrders;
  },
};

export default saleOrdersGetters;
