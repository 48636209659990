import { del, get, post, postFormData, put } from '@/utils/apiClient';

export const getPriceLists = async (params) => {
  const { success, data } = await get(`/price-lists`, { params }, true);
  if (!success) {
    throw new Error('API request failed');
  }
  return data.priceLists;
};

export const createPriceList = async (priceList, partnerIds) => {
  return await post(`/price-lists`, { ...priceList, partnerIds });
};

export const updatePriceList = async (id, priceList, partnerIds) => {
  return await put(`/price-lists/${id}`, { ...priceList, partnerIds });
};

export const deletePriceList = async (id) => {
  const { success, data } = await del(`/price-lists/${id}`);
  if (!success) {
    throw new Error('API request failed');
  }
  return data;
};

export const sendFileImport = async (formData) => {
  return await postFormData(`/price-lists/importPriceLists`, formData);
};

export const exportPriceList = async (item) => {
  try {
    const { success, data } = await get(`/price-lists/exportPriceList/${item.id}`);

    if (success) {
      const buffer = data.buffer.data;
      const blob = new Blob([new Uint8Array(buffer)], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const downloadUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `PriceList-${item.name}.xlsx`);
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(downloadUrl);
      link.remove();

      return { success: true };
    } else {
      throw new Error(data?.response.data.data.message || 'Failed to download the file');
    }
  } catch (error) {
    throw new Error(error.message || 'Error downloading the file:');
  }
};
