
import { defineComponent } from 'vue';
import { dispatchToast } from '@/utils/dispatchToast';
import {
  VDialog,
  VTextField,
  VBtn,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelTitle,
  VExpansionPanelText,
  VDataTable,
} from 'vuetify/components';

import { SaleLineDetailsForDataTable, VuetifyDataTableHeader } from '@/utils/helpers';

export default defineComponent({
  name: 'VuetifyDataTable',
  components: {
    VDataTable,
    VDialog,
    VTextField,
    VBtn,
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelTitle,
    VExpansionPanelText,
  },
  props: {
    columnsDefs: {
      type: Array as () => VuetifyDataTableHeader[],
      required: true,
      default: () => [],
    },
    rowsData: {
      type: Array as () => SaleLineDetailsForDataTable[],
      required: true,
      default: () => [],
    },
    class: {
      type: String,
      default: '',
    },
    updateRowFn: {
      type: Function,
      required: true,
    },
    itemKey: {
      type: String,
      required: true,
    },
    editableColumns: {
      type: Array,
      default: () => [],
    },
    textareaColumns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: '',
      isEditing: false, // State to control the dialog visibility
      editedValue: null as string | null, // To hold the edited value temporarily
      editedColumnKey: null as string | null, // To know which column is being edited
      editedItem: null as SaleLineDetailsForDataTable | null,
      groupBy: [{ key: 'familyCode', order: 'asc' }],
      sortBy: [{ key: 'partNumber', order: 'asc' }],
      customKeySort: {
        // alphanumeric string sorting for part numbers
        partNumber: (pn1: string, pn2: string) => {
          return pn1.localeCompare(pn2, 'en', { numeric: true });
        },
      },
      expandedPanel: [],
    };
  },

  mounted() {
    // This workaround is needed to fix the issue with the Vuetify DataTable of not having expanded by default
    const buttons = document.querySelectorAll(
      'button.v-btn.v-btn--icon.v-theme--light.v-btn--density-default.v-btn--size-small.v-btn--variant-text',
    );
    buttons.forEach((button: HTMLButtonElement) => {
      button.click();
    });
  },
  computed: {
    isDataTableVisible() {
      return this.columnsDefs.length && this.rowsData.length;
    },
    filteredColumnsDefs() {
      return this.columnsDefs.filter((columnDef) => columnDef.key !== 'familyCode') as VuetifyDataTableHeader[];
    },
    calcTableHeight() {
      const aboveTableHeight = 496;
      const maxHeight = window.screen.height - aboveTableHeight;
      return maxHeight;
    },
    totalRevenue() {
      const groupedRows = this.groupRowsByFamilyCode();
      const results = {};

      for (const familyCode in groupedRows) {
        results[familyCode] = groupedRows[familyCode].reduce((acc, item) => {
          const price = item.suggestedPrice || item.enginePrice || 0;
          return acc + parseFloat(price) * item.uomQty;
        }, 0);
      }

      results['total'] = this.rowsData.reduce((acc, item) => {
        const price = item.suggestedPrice || item.enginePrice || 0;
        return acc + parseFloat(price) * item.uomQty;
      }, 0);

      return results;
    },

    totalSaleOrderCost() {
      const groupedRows = this.groupRowsByFamilyCode();
      const results = {};

      for (const familyCode in groupedRows) {
        results[familyCode] = groupedRows[familyCode].reduce((acc, item) => {
          const cableCost = item.totalCableCost || 0;
          const otherCost = item.otherCosts || 0;
          const laborCost = item.labor || 0;

          const totalItemCost = parseFloat(cableCost) + parseFloat(otherCost) + parseFloat(laborCost);
          return acc + totalItemCost * item.uomQty;
        }, 0);
      }

      results['total'] = this.rowsData.reduce((acc, item) => {
        const cableCost = item.totalCableCost || 0;
        const otherCost = item.otherCosts || 0;
        const laborCost = item.labor || 0;

        const totalItemCost = parseFloat(cableCost) + parseFloat(otherCost) + parseFloat(laborCost);
        return acc + totalItemCost * item.uomQty;
      }, 0);

      return results;
    },

    itemsWithoutPriceCount() {
      return this.rowsData.filter((item) => !item.suggestedPrice && !item.enginePrice).length;
    },

    itemsWithoutCableCost() {
      return this.rowsData.filter((item) => !item.totalCableCost).length;
    },
    overallMargin() {
      const groupedRows = this.groupRowsByFamilyCode();
      const results = {};

      for (const familyCode in groupedRows) {
        let totalRevenueFromItemsWithBoth = 0;

        const margin = groupedRows[familyCode].reduce((acc, item) => {
          const revenue = item.suggestedPrice || item.enginePrice || 0;
          const cableCost = item.totalCableCost || 0;
          const otherCost = item.otherCosts || 0;
          const laborCost = item.labor || 0;
          const totalItemCost = parseFloat(cableCost) + parseFloat(otherCost) + parseFloat(laborCost);

          if (revenue > 0 && totalItemCost > 0) {
            totalRevenueFromItemsWithBoth += revenue * item.uomQty;
            return acc + (revenue - totalItemCost) * item.uomQty;
          }
          return acc;
        }, 0);

        results[familyCode] = totalRevenueFromItemsWithBoth === 0 ? 0 : (margin / totalRevenueFromItemsWithBoth) * 100;
      }

      // Total for all items
      let totalRevenueFromAllItemsWithBoth = 0;

      const totalMargin = this.rowsData.reduce((acc, item) => {
        const revenue = item.suggestedPrice || item.enginePrice || 0;
        const cableCost = item.totalCableCost || 0;
        const otherCost = item.otherCosts || 0;
        const laborCost = item.labor || 0;
        const totalItemCost = parseFloat(cableCost) + parseFloat(otherCost) + parseFloat(laborCost);

        if (revenue > 0 && totalItemCost > 0) {
          totalRevenueFromAllItemsWithBoth += revenue * item.uomQty;
          return acc + (revenue - totalItemCost) * item.uomQty;
        }
        return acc;
      }, 0);

      results['total'] = totalRevenueFromAllItemsWithBoth === 0 ? 0 : (totalMargin / totalRevenueFromAllItemsWithBoth) * 100;

      return results;
    },
    itemsExcludedFromMargin() {
      return this.rowsData.filter(
        (item) => !((item.suggestedPrice || item.enginePrice) && (item.totalCableCost || item.otherCosts || item.labor)),
      ).length;
    },
    uniqueFamilyCodes(): string[] {
      const rows: SaleLineDetailsForDataTable[] = this.rowsData;
      return Array.from(new Set(rows.map((item) => item.familyCode))).sort();
    },
  },
  methods: {
    isEditable(columnValue) {
      return this.editableColumns.includes(columnValue);
    },
    isTextareaColumn(columnKey) {
      return this.textareaColumns.includes(columnKey);
    },
    isClickable(columnKey) {
      return columnKey === this.itemKey;
    },
    getCellClass(columnDef) {
      if (this.isEditable(columnDef.key)) return 'editable-cell';
      if (this.isClickable(columnDef.key)) return 'clickable-cell';
      return '';
    },

    onCellClick(rowIndex: number, columnDef: VuetifyDataTableHeader, item: SaleLineDetailsForDataTable) {
      if (this.isClickable(columnDef.key)) {
        this.emitCellClicked(columnDef, item);
        return;
      }
      if (!this.isEditable(columnDef.key)) {
        return;
      }
      this.startEditing(rowIndex, columnDef, item);
    },

    emitCellClicked(columnDef, item) {
      this.$emit('cellClicked', {
        colDef: {
          field: columnDef.key,
        },
        data: item,
      });
    },
    cancelEdit() {
      this.isEditing = false;
    },
    async saveEdit() {
      const columnKey = this.editedColumnKey;
      const newValue = this.editedValue;

      // Construct the request body
      const reqBody = {
        [columnKey]: newValue,
      };

      const { success, data } = await this.updateRowFn(this.editedItem.id, reqBody);

      if (success) {
        // Update the local data after a successful update on the server
        this.$emit('update-item', {
          columnKey: this.editedColumnKey,
          value: this.editedValue,
          item: this.editedItem,
          data: data,
        });
      } else {
        dispatchToast(`Failed to update the cell: ${data.message}`, {
          type: 'error',
          timeout: false,
          draggable: false,
          closeOnClick: false,
        });
      }

      this.isEditing = false;
    },
    startEditing(rowIndex: number, columnDef: VuetifyDataTableHeader, item: SaleLineDetailsForDataTable) {
      this.editedItem = item;
      this.editedColumnKey = columnDef.key;
      this.editedValue = item[columnDef.key];
      this.isEditing = true;

      // After the Vue component has been updated, select the input text
      this.$nextTick(() => {
        if (this.isTextareaColumn(columnDef.key)) {
          if (this.$refs.editTextarea && this.$refs.editTextarea.$el) {
            this.$refs.editTextarea.$el.querySelector('textarea').focus();
          }
        } else {
          if (this.$refs.editInput && this.$refs.editInput.$el) {
            this.$refs.editInput.$el.querySelector('input').select();
          }
        }
      });
    },
    truncateContent(content, length = 30) {
      if (content && content.length > length) {
        return `${content.substring(0, length)}...`;
      }
      return content;
    },
    formatCurrency(value) {
      if (value !== null && value !== undefined && !isNaN(parseFloat(value))) {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(parseFloat(value));
      }
      return '';
    },
    formatPercentage(value) {
      if (value !== null && value !== undefined) {
        return `${parseFloat(value).toFixed(2)}%`;
      }
      return '';
    },
    formattedValue(columnDef, value, item) {
      const fallbackValue = item[columnDef.fallbackColumn];
      const isValueEmpty = value === '' || value == null;
      const displayValue = isValueEmpty && fallbackValue != null ? fallbackValue : value;
      const isFallback = isValueEmpty && fallbackValue != null;

      let formattedValue = '';

      switch (columnDef.formatter) {
        case 'currency':
          formattedValue = this.formatCurrency(displayValue);
          break;
        case 'percentage':
          formattedValue = this.formatPercentage(displayValue);
          break;
        default:
          formattedValue = displayValue;
          break;
      }

      return isFallback ? `<span class="fallback">${formattedValue}</span>` : formattedValue;
    },
    getTooltipText(columnDef, item) {
      const value = item[columnDef.key];
      const fallbackValue = item[columnDef.fallbackColumn];
      const isValueEmpty = value === '' || value == null;
      const isFallbackValueEmpty = fallbackValue === '' || fallbackValue == null;

      if (isValueEmpty && !isFallbackValueEmpty && columnDef.fallbackTooltip) {
        return item[columnDef.fallbackTooltip];
      }
      return '';
    },
    getIcon(item) {
      switch (item.enginePriceType) {
        case 'price_list':
          return 'mdi-tag-text-outline';
        case 'exact':
          return 'mdi-equal';
        case 'series':
          return 'mdi-playlist-check';
        case 'cost':
          return 'mdi-cash-multiple';
        default:
          return '';
      }
    },
    groupRowsByFamilyCode() {
      return this.rowsData.reduce((groups, item) => {
        const group = item.familyCode;
        if (!groups[group]) {
          groups[group] = [];
        }
        groups[group].push(item);
        return groups;
      }, {});
    },
  },
});
