<!-- Vue component -->
<template>
  <div class="multiselector flex flex-row relative">
    <multiselect
      :model-value="selectedValue"
      v-model="selectedValue"
      :value="selectedValue"
      mode="tags"
      :searchable="multiselectDefaultOptions.searchable"
      :closeOnSelect="multiselectDefaultOptions.closeOnSelect"
      :placeholder="multiselectDefaultOptions.placeholder"
      label="name"
      :track-by="multiselectDefaultOptions.trackBy"
      :options="sortedOptions"
      :taggable="true"
      @update:model-value="onInput()"
      deselectLabel="✓"
      selectedLabel="✓"
      :disabled="isDisabled || !options.length"
      :multiple="true"
    >
    </multiselect>
    <button
      v-if="selectedValue && Object.keys(selectedValue).length"
      :disabled="isDisabled || !options.length"
      :class="[
        'px-2 font-bold absolute right-6 lg:right-6 h-11',
        isDisabled || !options.length ? 'text-[#b6becc]' : 'text-gray-600',
      ]"
      @click="onUnselectAll"
    >
      &#10005;
    </button>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { ref, computed, watch } from 'vue';
import { sortObjectsByProperty } from '@/utils/helpers';

export default {
  name: 'CustomerSelect',
  components: { Multiselect },
  props: {
    options: {
      type: Array,
      required: true,
      default: function () {
        return [
          { name: 'Vue.js', code: 'vu' },
          { name: 'Javascript', code: 'js' },
          { name: 'Open Source', code: 'os' },
        ];
      },
    },
    multiselectOptions: {
      type: Object,
      required: false,
    },
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const selectedValue = ref(props.value);
    const multiselectDefaultOptions = computed(() => {
      return Object.assign(
        {
          placeholder: 'Search or add a tag',
          trackBy: 'code',
          searchable: false,
          closeOnSelect: false,
        },
        props.multiselectOptions,
      );
    });
    function onInput() {
      context.emit('update', selectedValue.value);
    }
    function onUnselectAll() {
      selectedValue.value = [];
      context.emit('update', selectedValue.value);
    }
    // created the separate watchers as both the values may effect in different conditions
    watch(
      () => props.options,
      () => {
        if (!props.options.length) {
          selectedValue.value = [];
        }
      },
    );
    watch(
      () => props.isSelected,
      () => {
        if (!props.isSelected) {
          selectedValue.value = [];
        }
      },
    );

    const sortedOptions = computed(() => {
      return sortObjectsByProperty(props.options, 'name', 'ASC');
    });

    return {
      selectedValue,
      onInput,
      multiselectDefaultOptions,
      onUnselectAll,
      sortedOptions,
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
$font-xs: 0.875rem;
$font-sm: 1.02rem;
$font-md: 1.125rem;

.multiselector {
  width: 320px;
}
.multiselect {
  .multiselect__content-wrapper {
    overflow-x: hidden;
  }
  .multiselect__placeholder {
    color: #858585;
    font-size: $font-sm;
    margin: 0;
    padding: 0;
  }
  .multiselect__input {
    padding: 0;
    margin: 0 0 10px 0;
    top: 2px;
  }
  .multiselect__tag {
    background-color: #f88d2a;
    .multiselect__tag-icon:after {
      color: white;
    }
  }
  .multiselect__option--highlight {
    background-color: #e5e7eb;
    color: #052a42;
    &:after {
      color: #052a42;
      background-color: #e5e7eb;
    }
  }
  .multiselect__option--selected {
    font-weight: 400;
  }
  .multiselect__select {
    background: transparent;
  }
  .multiselect__tags {
    max-height: 70px;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>
@/utils/helpers import { sortObjectsByProperty } from '@/utils/convertCamelCaseToTitleCase';
