<template>
  <div class="tabContainer tablesWrapper m-3" v-if="!isLoading && isSearched">
    <SaleLineTabs :tabs="tabs" />
    <div class="saleLine-content p-3 border border-gray-300">
      <div class="flex flex-row m-5 text-center justify-center items-center text-red-500" v-if="productsData.error.length">
        <i class="fa-solid fa-circle-exclamation"></i>
        <p class="ml-2 mb-[0.5px] font-semibold">
          {{ productsData.error }}
        </p>
      </div>
      <div
        class="flex flex-col m-5 text-center font-semibold justify-center items-center text-red-500"
        v-else-if="!Object.keys(productsData.data).length"
      >
        <div class="flex flex-row items-center">
          <i class="fa-solid fa-circle-exclamation"></i>
          <p class="ml-2">No records found in the History!</p>
        </div>
        <p class="text-neutral mt-2">
          <span class="text-primary underline cursor-pointer" @click="$emit('searchInSimulator')">Click here</span>
          to see the results in the Simulator.
        </p>
      </div>
      <DataGrid
        v-else
        domLayout="autoHeight"
        :columnsDefs="productsData.data.columnsDefs"
        :rowsData="productsData.data.rowsData"
        @cellClicked="(event) => $emit('cellClicked', event)"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import DataGrid from '@/components/DataGrid/DataGrid.vue';
import SaleLineTabs from '@/components/views/SaleLineTabs.vue';
import { computed } from 'vue';
export default {
  components: {
    SaleLineTabs,
    DataGrid,
  },
  props: {
    tabsAndProductsData: {
      type: Object,
    },
  },
  setup(props) {
    const store = useStore();

    const tabs = computed(() => props.tabsAndProductsData.tabs);

    const productsData = computed(() => {
      return props.tabsAndProductsData.data.find((productsData) => productsData.toggleValue);
    });

    const isSearched = computed(() => store.getters['salesHistory/isSearched']);

    const isLoading = computed(() => {
      return props.tabsAndProductsData.data.some((productsData) => productsData.isLoading);
    });

    return {
      tabs,
      productsData,
      isSearched,
      isLoading,
    };
  },
};
</script>
