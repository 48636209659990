import { get } from '@/utils/apiClient';

interface GetAllCustomers {
  (params: object): Promise<{
    success: boolean;
    data: any;
    pagination: object;
  }>;
}

interface GetCustomers {
  (params: object, activeCustomers?: boolean): Promise<{
    success: boolean;
    data: any;
    pagination: object;
  }>;
}

export const getCustomers: GetCustomers = async (params, onlyActiveCustomers = false) => {
  const endpoint = onlyActiveCustomers ? `/customers/active` : `/customers`;
  const { success, data } = await get(endpoint, { params }, true);
  let response;
  if (success) {
    const { results, ...pagination } = data;
    response = {
      success,
      data: results,
      pagination,
    };
  } else {
    response = { success, data, pagination: {} };
  }
  return response;
};
