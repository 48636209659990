import simulatorActions from './actions';
import simulatorGetters from './getters';
import simulatorMutations from './mutations';

export const sameProductInitialValue = {
  partNumber: '',
  isSearched: false,
  isLoading: false,
  data: {},
  pagination: {},
  error: '',
  toggleValue: false,
};
export const similarProductsInitialValue = {
  partNumbers: [],
  isSearched: false,
  isLoading: false,
  data: {},
  pagination: {},
  error: '',
  toggleValue: false,
};
export const sameCustomerSameProductInitialValue = {
  partNumber: '',
  customer: '',
  isSearched: false,
  isLoading: false,
  data: {},
  pagination: {},
  error: '',
  toggleValue: false,
};
export const sameCustomerSimilarProductsInitialValue = {
  similarPartNumbers: [],
  customer: '',
  isSearched: false,
  isLoading: false,
  data: {},
  pagination: {},
  error: '',
  toggleValue: false,
};
export const similarCustomersSameProductInitialValue = {
  partNumber: '',
  customers: [],
  isSearched: false,
  isLoading: false,
  data: {},
  pagination: {},
  error: '',
  toggleValue: false,
};
export const similarCustomersSimilarProductsInitialValue = {
  similarPartNumbers: [],
  customers: [],
  isSearched: false,
  isLoading: false,
  data: {},
  pagination: {},
  error: '',
  toggleValue: false,
};
export const statisticsInitialValue = {
  isSearched: false,
  isLoading: false,
  error: '',
  partNumber: '',
  wins: {
    latest: {},
    highestPrice: {},
    lowestPrice: {},
  },
};
const simulatorModule = {
  namespaced: true,
  state: {
    isSearched: false,
    partNumber: '',
    oldPartNumber: '',
    saleLineToPrice: {},
    selectedCustomers: [],
    customersList: [],
    sameProduct: {
      ...sameProductInitialValue,
    },
    similarProducts: {
      ...similarProductsInitialValue,
    },
    sameCustomerSameProduct: {
      ...sameCustomerSameProductInitialValue,
    },
    sameCustomerSimilarProducts: {
      ...sameCustomerSimilarProductsInitialValue,
    },
    similarCustomersSameProduct: {
      ...similarCustomersSameProductInitialValue,
    },
    similarCustomersSimilarProducts: {
      ...similarCustomersSimilarProductsInitialValue,
    },
    statistics: {
      sameCustomerSameProduct: {
        ...statisticsInitialValue,
      },
    },
  },
  mutations: simulatorMutations,
  actions: simulatorActions,
  getters: simulatorGetters,
};

export default simulatorModule;
