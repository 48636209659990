
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Input from '@/components/Input.vue';
import CustomerSelect from '@/components/CustomerSelect.vue';
import SimulatorTabsContainer from '@/components/views/simulator/SimulatorTabsContainer.vue';
import PricingForm from '@/components/views/simulator/PricingForm.vue';
import { ref, computed, onMounted } from 'vue';
import EventTracker from '@/utils/EventTracker';
import { DynamicDataObject } from '@/types/shared.types';
import config from '@/config';
import { getTotalCost } from '@/utils/helpers';

export default {
  name: 'SimulatorView',
  components: {
    Input,
    CustomerSelect,
    SimulatorTabsContainer,
    PricingForm,
  },
  computed: {
    totalCost() {
      const { saleLineToPrice } = this;
      const totalCost = getTotalCost(
        saleLineToPrice.cableCost,
        saleLineToPrice.quotedProductLength,
        saleLineToPrice.laborCost,
        saleLineToPrice.otherCosts,
      );
      return totalCost ? totalCost.toFixed(2) : null;
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const multiselectOptions = {
      placeholder: 'Search customer',
      trackBy: 'id',
      searchable: true,
      closeOnSelect: true,
    };

    const isCustomerSelected = ref(true);

    const saleLineToPrice = computed(() => store.getters['simulator/saleLineToPrice']);
    const partNumber = computed(() => store.getters['simulator/partNumber']);
    const isSimilarCustomerSearch = computed(() => selectedCustomers.value?.length === 1);
    const oldPartNumber = computed(() => store.getters['simulator/oldPartNumber']);
    const customersList = computed(() => store.getters['simulator/customersList']);
    const selectedCustomers = computed(() => store.getters['simulator/selectedCustomers']);
    const allProductsData = computed(() => store.getters['simulator/allProductsData']);
    const statistics = computed(() => store.getters['simulator/statistics']);

    const isSearched = computed(() => store.getters['simulator/isSearched']);
    const isLoading = computed(() => {
      const isProductsLoading = allProductsData.value.some((productsData: any) => productsData.isLoading);
      const isStatisticsLoading = statistics.value.sameCustomerSameProduct.isLoading;

      return isProductsLoading || isStatisticsLoading;
    });

    // ==================== Life Cycle ====================
    onMounted(() => {
      const selectedCustomersNames = selectedCustomers.value?.map((customer: DynamicDataObject) => customer.name);

      EventTracker.simulatorView({
        partNumber: partNumber.value || null,
        customers: selectedCustomersNames || null,
      });
    });

    // ==================== Tabs Container Data ====================
    const sameSimilarProductsData = computed(() => store.getters['simulator/sameSimilarProductsData']);
    const twoByTwoMatrixProductsData = computed(() => store.getters['simulator/twoByTwoMatrixProductsData']);

    // ==================== Methods ====================

    const onPartNumberInput = (event: any) => {
      store.commit('simulator/setPartNumber', event.target.value.trim());
    };

    const openLink = () => {
      const url = `${config.baseUrls.erp}/web?#id=${saleLineToPrice.value.saleOrder.erpId}&action=484&model=sale.order&view_type=form&cids=1&menu_id=300`;
      window.open(url, '_blank');
    };

    const onSearchReset = async () => {
      isCustomerSelected.value = false;
      store.commit('simulator/resetAllValues', {
        allCustomers: [],
      });
      store.commit('simulator/resetAllData');
    };

    const onSearchText = async (event: any) => {
      const isPartNumberSearched = event.key === 'Enter' || event.type === 'click';
      const isFilterUpdated = event.type === 'updateFilter';

      if (isPartNumberSearched || isFilterUpdated) {
        const selectedCustomersNames = selectedCustomers.value?.map((customer: DynamicDataObject) => customer.name);
        EventTracker.simulatorSearch({
          partNumber: partNumber.value || null,
          customers: selectedCustomersNames || null,
        });

        // Set partNumber value for Request if input changed
        if (isPartNumberSearched && oldPartNumber.value !== partNumber.value) {
          store.commit('simulator/setOldPartNumber', partNumber.value);
          store.commit('simulator/setSelectedCustomers', []);
          store.commit('simulator/setCustomersList', []);
        }

        // Reset all values and Return if no input is selected
        if (!oldPartNumber.value) {
          onSearchReset();
          return;
        }

        // Get histories
        store.dispatch('simulator/simulatorSearch', {
          partNumber: oldPartNumber.value.toUpperCase(),
          selectedCustomers: selectedCustomers.value,
        });
      }
    };

    const onCustomerNameChange = (customersInfo: any) => {
      const customers = customersInfo.map((item: any) => item);
      store.commit('simulator/setSelectedCustomers', customers);
      onSearchText(new Event('updateFilter'));
    };

    return {
      partNumber,
      oldPartNumber,
      isSearched,
      isLoading,
      customersList,
      selectedCustomers,
      multiselectOptions,
      isCustomerSelected,
      isSimilarCustomerSearch,
      sameSimilarProductsData,
      twoByTwoMatrixProductsData,
      router,
      saleLineToPrice,
      onPartNumberInput,
      onSearchText,
      onCustomerNameChange,
      onSearchReset,
      openLink,
    };
  },
};
