import { ServerResponse } from '@/types/shared.types';
import { get, post, put } from '@/utils/apiClient';

export const getSaleOrders: GetSaleOrders = async (status) => {
  const response = await get('/sale-orders', { params: { status } });
  return response;
};

export const getSaleOrderStates: GetSaleOrderStates = async () => {
  const response = await get('/sale-orders/states');
  return response;
};

export const updateSaleOrder: UpdateSaleOrder = async (saleOrderId, saleOrder) => {
  const response = await put(`/sale-orders/${saleOrderId}`, saleOrder);
  return response;
};

export const syncSalesOrderLinesBySaleOrderName = async (saleOrderName: string) => {
  const response = await post(`/sale-orders/syncSaleLines`, {
    saleOrderName,
  });
  return response;
};

export const getSaleOrderDetails = async (saleOrderId: string) => {
  const response = await get(`/sale-orders/${saleOrderId}`);
  return response;
};

/* =====================================================================================================================
 * ================================================== Interfaces =======================================================
 * =====================================================================================================================
 */

interface GetSaleOrders {
  (status: string): Promise<ServerResponse>;
}
interface GetSaleOrderStates {
  (): Promise<ServerResponse>;
}
interface UpdateSaleOrder {
  (saleOrderId: string, saleOrder: { pricingStatus?: string; notes?: string }): Promise<ServerResponse>;
}
