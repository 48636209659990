<template>
  <div class="tabContainer tablesWrapper m-4" v-if="!isLoading && isSearched">
    <SaleLineTabs :tabs="tabs" @tabClick="onTabClick" />
    <div class="saleLine-content p-3 border border-gray-300">
      <div v-if="!isTabChanged" class="flex flex-row m-5 text-center justify-center items-center text-primary">
        <i class="fa-solid fa-arrows-rotate"></i>
        <p class="ml-2 mb-[0.5px] font-semibold">Searching for Results...</p>
      </div>
      <div v-else>
        <div class="flex flex-row m-5 text-center justify-center items-center text-red-500" v-if="productsData.error.length">
          <i class="fa-solid fa-circle-exclamation"></i>
          <p class="ml-2 mb-[0.5px] font-semibold">
            {{ productsData.error }}
          </p>
        </div>
        <div
          class="flex flex-row m-5 text-center justify-center items-center text-red-500"
          v-else-if="!Object.keys(productsData.data).length"
        >
          <i class="fa-solid fa-circle-exclamation"></i>
          <p class="ml-2 mb-[0.5px] font-semibold">No Records Found!</p>
        </div>
        <div v-else>
          <div v-if="productsData.statistics">
            <Statistics :data="productsData.statistics" />
          </div>
          <DataGrid
            domLayout="autoHeight"
            :columnsDefs="productsData.data.columnsDefs"
            :rowsData="productsData.data.rowsData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import DataGrid from '@/components/DataGrid/DataGrid.vue';
import Statistics from '@/components/layout/Statistics.vue';
import SaleLineTabs from '@/components/views/SaleLineTabs.vue';
import { ref, computed } from 'vue';
export default {
  components: {
    SaleLineTabs,
    DataGrid,
    Statistics,
  },
  props: {
    dataType: {
      type: String,
    },
    tabsAndProductsData: {
      type: Object,
    },
  },
  setup(props) {
    const store = useStore();
    const isTabChanged = ref(true);

    const resetMutationName = computed(() => {
      let resetMutationName = '';
      if (props.dataType === 'SameSimilar') {
        resetMutationName = 'resetToggleValueForSameSimilar';
      } else if (props.dataType === 'TwoByTwoMatrix') {
        resetMutationName = 'resetToggleValueForTwoByTwoMatrix';
      }
      return resetMutationName;
    });

    const tabs = computed(() => props.tabsAndProductsData.tabs);

    const productsData = computed(() => {
      return props.tabsAndProductsData.data.find((productsData) => productsData.toggleValue);
    });

    const isSearched = computed(() => store.getters['simulator/isSearched']);
    const isLoading = computed(() => {
      return props.tabsAndProductsData.data.some((productsData) => productsData.isLoading);
    });

    const onTabClick = (tab) => {
      if (!tab.isActive) {
        isTabChanged.value = false;
        store.commit(`simulator/${resetMutationName.value}`);
        store.commit(`simulator/${tab.mutationOnTabClick}`, {
          toggleValue: true,
        });
        setTimeout(() => {
          isTabChanged.value = true;
        }, 0);
      }
    };

    return {
      isTabChanged,
      tabs,
      productsData,
      isSearched,
      isLoading,
      onTabClick,
    };
  },
};
</script>
