
import { useStore } from 'vuex';
import { onMounted } from 'vue';
import EventTracker from '@/utils/EventTracker';

export default {
  setup() {
    const store = useStore();

    onMounted(async () => {
      new EventTracker();
      await store.dispatch('setAllCustomers');
      await store.dispatch('setSaleOrderStates');
    });
  },
};
