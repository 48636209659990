<template>
  <div class="flex flex-col gap-8">
    <div name="cost-based-pricing-input-group" class="flex">
      <div class="flex flex-1 flex-row flex-wrap gap-y-5">
        <div name="reference-product" class="w-1/2 pr-3">
          <label class="label pb-0 font-semibold">Reference Product</label>
          <div class="divider my-0"></div>
          <div class="flex gap-3">
            <PricingFormInput
              name="lengthProductOne"
              :label="`Length ${lengthUnitOfPartNumberToPrice}`"
              :disabled="isPricingInputsDisabled"
              type="number"
              :step="step"
              :min="0"
              v-model="saleLineToPrice.referenceProduct1Length"
              @input="onChangeCalculatingData"
            />
            <PricingFormInput
              name="priceProductOne"
              label="Price ($)"
              :disabled="isPricingInputsDisabled"
              type="number"
              :step="step"
              :min="0"
              v-model="saleLineToPrice.referenceProduct1Price"
              @input="onChangeCalculatingData"
            />
          </div>
        </div>
        <div name="costing-information" class="w-1/2 pl-3">
          <label class="label pb-0 font-semibold">Costing Information</label>
          <div class="divider my-0"></div>
          <div class="flex gap-3">
            <PricingFormInput
              name="cableCost"
              label="Cable Cost Per Unit ($)"
              :disabled="isPricingInputsDisabled"
              type="number"
              :step="step"
              :min="0"
              v-model="saleLineToPrice.cableCost"
              @input="onChangeCalculatingData"
            />
            <PricingFormInput
              name="expectedMargin"
              label="Expected Cable Margin (%)"
              :disabled="isPricingInputsDisabled"
              type="number"
              :step="step"
              :min="0"
              v-model="saleLineToPrice.expectedMargin"
              @input="onChangeCalculatingData"
            />
          </div>
        </div>
        <div name="price-calculation" class="w-1/2 pr-3">
          <label class="label pb-0 font-semibold">Price Calculation</label>
          <div class="divider my-0"></div>
          <div class="flex gap-3">
            <PricingFormInput
              name="cableToPriceLength"
              :label="`Quoted Product's Length ${lengthUnitOfPartNumberToPrice}`"
              :disabled="true"
              type="number"
              :step="step"
              :min="0"
              v-model="saleLineToPrice.quotedProductLength"
              @input="onChangeCalculatingData"
            />
            <PricingFormInput
              name="costBasedSuggestedPrice"
              label="Suggested Price ($)"
              :disabled="isPricingInputsDisabled"
              type="number"
              :step="step"
              :min="0"
              :isValid="isSuggestedPriceValid"
              errorMessage="Please review the pricing"
              :modelValue="costBasedSuggestedPriceComputed"
              @input="onChangeCostBasedSuggestedPrice"
            />
          </div>
        </div>
        <div name="additional-costing-information" class="w-1/2 pl-3">
          <label class="label pb-0 font-semibold">Additional Costing Information</label>
          <div class="divider my-0"></div>
          <div class="flex gap-3">
            <PricingFormInput
              name="laborCost"
              label="Labor Cost ($)"
              :disabled="isPricingInputsDisabled"
              type="number"
              :step="step"
              :min="0"
              v-model="saleLineToPrice.laborCost"
              @input="onChangeCalculatingData"
            />
            <PricingFormInput
              name="otherCosts"
              label="Other Costs ($)"
              :disabled="isPricingInputsDisabled"
              type="number"
              :step="step"
              :min="0"
              v-model="saleLineToPrice.otherCosts"
              @input="onChangeCalculatingData"
            />
            <PricingFormInput
              name="odooCost"
              label="Odoo Costs ($)"
              :disabled="true"
              type="number"
              v-model="saleLineToPrice.erpCost"
            />
          </div>
          <br />
          <div class="flex gap-3 flex-row-reverse">
            <v-chip v-if="totalCost">Total cost: ${{ totalCost }}</v-chip>
          </div>
        </div>
      </div>

      <div class="divider divider-horizontal"></div>

      <div class="w-1/3">
        <label for="notes" class="label pb-0 font-semibold">Notes</label>
        <div class="divider mt-0"></div>
        <textarea
          id="notes"
          class="textarea textarea-bordered text-base border-2 w-full h-4/6 border-gray-200 focus:border-gray-300 bg-gray-50 disabled:bg-gray-100 rounded-md focus:outline-none"
          :disabled="isPricingInputsDisabled"
          placeholder="Type here..."
          v-model="saleLineToPrice.notes"
        ></textarea>
      </div>
    </div>
    <div name="pricing-button-group" class="flex gap-2">
      <button
        :disabled="pricingForm.isLoading || !isSuggestedPriceValid || !dataHasBeenChanged"
        class="btn btn-md btn-primary rounded-sm w-24 min-h-fit h-10 text-white"
        @click="onPricingFormEvents.submit"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import PricingFormInput from '@/components/views/simulator/PricingFormInput.vue';
import { ref, computed, reactive } from 'vue';
import { updateSaleLine } from '@/services/pricingHistories.service';
import { dispatchToast } from '@/utils/dispatchToast';
import EventTracker from '@/utils/EventTracker';
import { watch } from 'vue';

export default {
  name: 'CostBasedForm',
  components: {
    PricingFormInput,
  },
  props: {
    totalCost: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup() {
    const store = useStore();
    const saleLineToPrice = computed(() => store.getters['simulator/saleLineToPrice']);
    const step = '0.01';
    const initialStates = {
      pricingForm: {
        isLoading: false,
        error: '',
      },
    };

    const pricingForm = reactive(initialStates.pricingForm);

    const costBasedSuggestedPrice = ref(null);

    const dataHasBeenChanged = ref(false);

    /**
     * ===========================================================
     * ========================= Computed ========================
     * ===========================================================
     */
    const isPricingInputsDisabled = computed(() => pricingForm.isLoading);

    const lengthUnitOfPartNumberToPrice = computed(() => {
      const unit = saleLineToPrice.value.lengthInfo.unit;
      let lengthUnit = '';

      if (unit === 'F') {
        lengthUnit = '(Feet)';
      } else if (unit === 'M') {
        lengthUnit = '(Meters)';
      } else if (unit === 'I') {
        lengthUnit = '(Inches)';
      } else if (unit === 'CM') {
        lengthUnit = '(Centimeters)';
      }

      return lengthUnit;
    });

    // ========================= Cost Based Suggested Price Calculation =========================
    const costBasedSuggestedPriceCalculated = computed(() => {
      const requiredValues = {
        referenceProductLength: saleLineToPrice.value?.referenceProduct1Length,
        referenceProductPrice: saleLineToPrice.value?.referenceProduct1Price,
        costPerUnit: saleLineToPrice.value?.cableCost,
        expectedMargin: saleLineToPrice.value?.expectedMargin,
        quotedProductLength: saleLineToPrice.value?.quotedProductLength,
      };
      const hasDataForCalculations = Object.values(requiredValues).every((val) => val);

      let calculatedSuggestedPrice = null;

      if (hasDataForCalculations) {
        const { referenceProductLength, referenceProductPrice, costPerUnit, expectedMargin, quotedProductLength } =
          requiredValues;

        const calculatedExpectedMargin = Number(expectedMargin) / 100;

        // Cost Based SP = RP price + [(QP length - RP length)*cable cost per foot/(1-Expected cable margin)]
        calculatedSuggestedPrice =
          Number(referenceProductPrice) +
          Number(
            ((Number(quotedProductLength) - Number(referenceProductLength)) * Number(costPerUnit)) /
              (1 - Number(calculatedExpectedMargin)),
          );
      }

      return calculatedSuggestedPrice;
    });
    const costBasedSuggestedPriceComputed = computed(() => {
      let computedSuggestedPrice = null;

      if (saleLineToPrice.value.pricingType === 'Cost Based') {
        computedSuggestedPrice = saleLineToPrice.value.suggestedPrice;
      }
      if (costBasedSuggestedPriceCalculated.value !== null) {
        computedSuggestedPrice = Number(costBasedSuggestedPriceCalculated.value).toFixed(2);
      }
      if (saleLineToPrice.value.suggestedPrice !== null && !dataHasBeenChanged.value) {
        computedSuggestedPrice = saleLineToPrice.value.suggestedPrice;
      }
      if (costBasedSuggestedPrice.value !== null) {
        computedSuggestedPrice = costBasedSuggestedPrice.value;
      }

      return computedSuggestedPrice?.toString();
    });

    const isSuggestedPriceValid = computed(() => {
      const isNotSuggested = [undefined, null, ''].includes(costBasedSuggestedPriceComputed.value);

      return isNotSuggested || costBasedSuggestedPriceComputed.value > 0;
    });

    /**
     * ===========================================================
     * ========================= Methods =========================
     * ===========================================================
     */

    const onChangeCostBasedSuggestedPrice = (e) => {
      costBasedSuggestedPrice.value = e.target.value;
      dataHasBeenChanged.value = true;
    };

    const onChangeCalculatingData = () => {
      dataHasBeenChanged.value = true;
    };

    let previousState = JSON.parse(JSON.stringify(saleLineToPrice.value));

    watch(
      saleLineToPrice.value,
      (newVal) => {
        if (newVal.referenceProduct1 !== previousState.referenceProduct1) {
          dataHasBeenChanged.value = true;
        }
        // Update the previous state with the current state
        previousState = JSON.parse(JSON.stringify(newVal));
      },
      { deep: true },
    );

    /**
     * ===========================================================
     * =================== Form Events Handlers ==================
     * ===========================================================
     */
    const onPricingFormEvents = {
      async submit() {
        pricingForm.isLoading = true;

        EventTracker.calculatorSubmit();

        const isPriceSuggested = Boolean(costBasedSuggestedPriceComputed.value);

        const reqBody = {
          pricingType: isPriceSuggested ? 'Cost Based' : null,
          perFootAdder: null,
          cableCost: saleLineToPrice.value.cableCost || null,
          expectedMargin: saleLineToPrice.value.expectedMargin || null,
          laborCost: saleLineToPrice.value.laborCost || null,
          otherCosts: saleLineToPrice.value.otherCosts || null,
          suggestedPrice: costBasedSuggestedPriceComputed.value || null,
          notes: saleLineToPrice.value.notes || '',
          quotedProductLength: saleLineToPrice.value.quotedProductLength || null,
          referenceProduct1: saleLineToPrice.value.referenceProduct1 || null,
          referenceProduct1Length: saleLineToPrice.value.referenceProduct1Length || null,
          referenceProduct1Price: saleLineToPrice.value.referenceProduct1Price || null,
        };

        const { success, data } = await updateSaleLine(saleLineToPrice.value.id, reqBody);

        if (!success) {
          pricingForm.error = data.message;
          dispatchToast('Unable to save the pricing information!', {
            type: 'error',
          });
        } else {
          store.commit('simulator/setSaleLineToPrice', {
            ...saleLineToPrice.value,
            ...data,
          });
          dispatchToast('Pricing Saved Successfully!', {
            type: 'success',
          });
          dataHasBeenChanged.value = false;
        }

        pricingForm.isLoading = false;
      },
    };

    return {
      saleLineToPrice,
      step,
      pricingForm,
      isPricingInputsDisabled,
      lengthUnitOfPartNumberToPrice,
      costBasedSuggestedPriceComputed,
      isSuggestedPriceValid,
      onPricingFormEvents,
      onChangeCostBasedSuggestedPrice,
      onChangeCalculatingData,
      dataHasBeenChanged,
    };
  },
};
</script>
