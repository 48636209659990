const simulatorGetters = {
  isSearched(state: any) {
    return state.isSearched;
  },
  partNumber(state: any) {
    return state.partNumber;
  },
  saleLineToPrice(state: any) {
    return state.saleLineToPrice;
  },
  oldPartNumber(state: any) {
    return state.oldPartNumber;
  },
  selectedCustomers(state: any) {
    return state.selectedCustomers;
  },
  customersList(state: any) {
    return state.customersList;
  },
  toggleValue(state: any) {
    return state.toggleValue;
  },
  sameProduct(state: any) {
    return state.sameProduct;
  },
  similarProducts(state: any) {
    return state.similarProducts;
  },
  sameCustomerSameProduct(state: any) {
    return state.sameCustomerSameProduct;
  },
  sameCustomerSimilarProducts(state: any) {
    return state.sameCustomerSimilarProducts;
  },
  similarCustomersSameProduct(state: any) {
    return state.similarCustomersSameProduct;
  },
  similarCustomersSimilarProducts(state: any) {
    return state.similarCustomersSimilarProducts;
  },
  allProductsData(state: any) {
    return [
      state.sameProduct,
      state.similarProducts,
      state.sameCustomerSameProduct,
      state.sameCustomerSimilarProducts,
      state.similarCustomersSameProduct,
      state.similarCustomersSimilarProducts,
    ];
  },
  statistics(state: any) {
    return state.statistics;
  },
  sameCustomerSameProductStatistics(state: any) {
    const statistics = [
      {
        title: 'Last Win',
        customer: 'Same Customer',
        saleLineData: state.statistics.sameCustomerSameProduct.wins.latest,
      },
      {
        title: 'Highest Price Win',
        customer: 'Same Customer',
        saleLineData: state.statistics.sameCustomerSameProduct.wins.highestPrice,
      },
      {
        title: 'Lowest Price Win',
        customer: 'Same Customer',
        saleLineData: state.statistics.sameCustomerSameProduct.wins.lowestPrice,
      },
    ];
    return statistics;
  },
  sameSimilarProductsData(state: any) {
    const sameSimilarData = {
      tabs: [
        {
          title: 'Same Products',
          isActive: state.sameProduct.toggleValue,
          totalResults: state.sameProduct.pagination?.totalResults,
          mutationOnTabClick: 'setSameProduct',
        },
        {
          title: 'Similar Products',
          isActive: state.similarProducts.toggleValue,
          totalResults: state.similarProducts.pagination?.totalResults,
          mutationOnTabClick: 'setSimilarProducts',
        },
      ],
      data: [state.sameProduct, state.similarProducts],
    };
    return sameSimilarData;
  },
  twoByTwoMatrixProductsData(state: any, getters: any) {
    const twoByTwoMatrixData = {
      tabs: [
        {
          title: 'Same Customer x Same Product',
          isActive: state.sameCustomerSameProduct.toggleValue,
          totalResults: state.sameCustomerSameProduct.pagination?.totalResults,
          mutationOnTabClick: 'setSameCustomerSameProduct',
        },
        {
          title: 'Same Customer x Similar Products',
          isActive: state.sameCustomerSimilarProducts.toggleValue,
          totalResults: state.sameCustomerSimilarProducts.pagination?.totalResults,
          mutationOnTabClick: 'setSameCustomerSimilarProducts',
        },
        {
          title: 'Similar Customers x Same Product',
          isActive: state.similarCustomersSameProduct.toggleValue,
          totalResults: state.similarCustomersSameProduct.pagination?.totalResults,
          mutationOnTabClick: 'setSimilarCustomersSameProduct',
        },
        {
          title: 'Similar Customers x Similar Products',
          isActive: state.similarCustomersSimilarProducts.toggleValue,
          totalResults: state.similarCustomersSimilarProducts.pagination?.totalResults,
          mutationOnTabClick: 'setSimilarCustomersSimilarProducts',
        },
      ],
      data: [
        {
          ...state.sameCustomerSameProduct,
          statistics: getters.sameCustomerSameProductStatistics,
        },
        state.sameCustomerSimilarProducts,
        state.similarCustomersSameProduct,
        state.similarCustomersSimilarProducts,
      ],
    };

    return twoByTwoMatrixData;
  },
};

export default simulatorGetters;
