import saleHistoryActions from './actions';
import saleHistoryGetters from './getters';
import saleHistoryMutations from './mutations';

export const sameProductInitialValue = {
  partNumber: '',
  isSearched: false,
  isLoading: false,
  data: {},
  pagination: {},
  error: '',
  toggleValue: false,
};

const saleHistoryModule = {
  namespaced: true,
  state: {
    isSearched: false,
    partNumber: '',
    oldPartNumber: '',
    selectedCustomers: [],
    customersList: [],
    toggleValue: false,
    sameProduct: {
      ...sameProductInitialValue,
    },
  },

  mutations: saleHistoryMutations,
  actions: saleHistoryActions,
  getters: saleHistoryGetters,
};
export default saleHistoryModule;
