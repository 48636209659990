<template>
  <div class="navbar bg-neutral fixed z-50">
    <div class="navbar-start">
      <img class="w-32 ml-2" src="@/assets/img/logo-dark.svg" alt="viaPhoton Logo" />
    </div>
    <div class="navbar-center">
      <span class="text-base text-white font-semibold tracking-wide navbar-title">Pricing Engine </span>
    </div>
    <div class="navbar-end mr-2">
      <div class="dropdown dropdown-end" v-if="user">
        <label tabindex="0" class="btn btn-ghost btn-circle avatar w-10">
          <div class="rounded-full">
            <img class="w-14" :src="user.picture" />
          </div>
        </label>
        <ul
          tabindex="0"
          :class="[
            !user.email ? 'w-60' : 'w-auto',
            'p-2 shadow-lg menu menu-compact dropdown-content bg-white rounded-md text-neutral',
          ]"
        >
          <div class="flex flex-row hero-content px-6">
            <img :src="user.picture" class="rounded-full w-10 shadow-2xl" />
            <div>
              <h1 class="username font-normal">{{ user?.name }}</h1>
              <p class="email font-normal">{{ user?.email }}</p>
            </div>
          </div>

          <hr class="mt-1" />
          <li @click="onLogout" class="text-sm text-neutral p-3 cursor-pointer hover:bg-base-100">Logout</li>
        </ul>
      </div>
      <div v-else>
        <router-link to="/login">
          <button class="btn btn-circle btn-sm rounded-full btn-primary text-base text-neutral">
            <i class="fa-solid fa-right-to-bracket"></i>
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  name: 'HeaderComponent',
  setup() {
    const { user, logout } = useAuth0();

    /* ==================== Methods ==================== */
    function onLogout() {
      logout({ returnTo: window.location.origin });
    }

    return {
      user,
      onLogout,
    };
  },
};
</script>

<style lang="scss" scoped>
.navbar-title {
  word-spacing: 3px;
}
</style>
