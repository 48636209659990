<template>
  <div>
    <select
      class="select select-sm select-bordered focus:outline-none w-36"
      :value="modelValue"
      @change="$emit('update:modelValue', $event?.target?.value)"
    >
      <option v-for="option in options" :key="option.value">
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'AppSelect',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    options: {
      type: Array,
      default: () => [], // { name: "Name", value: "value" }
    },
  },
};
</script>
