<template>
  <v-container>
    <v-row v-if="showTable">
      <v-col cols="12" sm="4">
        <v-combobox v-model="partnerFilter" :items="usedPartners" label="Filter by partner" class="mb-3"></v-combobox>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field v-model="partNumberFilter" label="Filter by part number" class="mb-3"></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch v-model="showExpired" label="Show expired price lists" class="mb-3" color="primary"></v-switch>
      </v-col>
    </v-row>
    <div v-if="showTable">
      <v-data-table :headers="headers" :items="filteredPriceLists" v-model:sort-by="sortBy" class="elevation-1">
        <template v-slot:item="{ item }">
          <tr :class="{ expired: isExpired(item.endDate) }">
            <td>{{ item.name }}</td>
            <td>{{ formatDate(item.startDate) }}</td>
            <td>{{ formatDate(item.endDate) }}</td>
            <td>
              <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
              <v-icon small class="mr-2" @click="deleteItem(item)"> mdi-delete </v-icon>
              <v-icon small @click="exportPriceList(item)"> mdi-file-excel </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="d-flex justify-end mt-4">
        <v-btn color="primary" @click="createNewPriceList">Create New Price List</v-btn>
      </div>
    </div>
    <div v-else>
      <PriceListForm :priceList="selectedPriceList" @submit="handleSubmit" @cancel="handleCancel"></PriceListForm>
    </div>
  </v-container>
  <ConfirmationModal
    v-if="showDeleteConfirmation"
    confirmButtonText="Yes"
    cancelButtonText="No"
    title="Delete Price List"
    message="Are you sure you want to delete this Price List?"
    @confirm="confirmDelete"
    @cancel="cancelDelete"
  />
</template>

<script>
import { dispatchToast } from '@/utils/dispatchToast';
import { VBtn, VDataTable, VCombobox, VTextField } from 'vuetify/components';
import PriceListForm from './PriceListForm.vue';
import { getPriceLists, deletePriceList, exportPriceList } from '@/services/priceList.service';
import ConfirmationModal from '@/components/ConfirmationModal.vue';

export default {
  components: {
    VDataTable,
    PriceListForm,
    VBtn,
    ConfirmationModal,
    VCombobox,
    VTextField,
  },
  name: 'PriceListsView',
  data() {
    return {
      priceLists: [],
      headers: [
        { title: 'Price List Name', key: 'name' },
        {
          title: 'Start Date',
          key: 'startDate',
          value: (item) => this.formatDate(item.startDate),
          sortRaw(a, b) {
            return new Date(a.startDate) - new Date(b.startDate);
          },
        },
        {
          title: 'End Date',
          key: 'endDate',
          value: (item) => this.formatDate(item.endDate),
          sortRaw(a, b) {
            return new Date(a.endDate) - new Date(b.endDate);
          },
        },
        { title: 'Actions', key: 'action', sortable: false },
      ],
      sortBy: [{ key: 'endDate', order: 'desc' }],
      showTable: true,
      selectedPriceList: null,
      showDeleteConfirmation: false,
      itemToDelete: null,
      partnerFilter: null,
      partNumberFilter: '',
      showExpired: false,
    };
  },
  computed: {
    usedPartners() {
      const partners = this.priceLists.flatMap((priceList) => priceList.partners.map((partner) => partner.name));
      return [...new Set(partners)];
    },
    filteredPriceLists() {
      const now = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()));

      return this.priceLists.filter((priceList) => {
        const matchesPartner =
          !this.partnerFilter ||
          priceList.partners.some((partner) => partner.name.toLowerCase().includes(this.partnerFilter.toLowerCase()));

        const matchesPartNumber =
          !this.partNumberFilter ||
          priceList.priceRanges.some((range) =>
            range.partNumber.toLowerCase().includes(this.partNumberFilter.toLowerCase()),
          ) ||
          priceList.priceStatics.some((priceStatic) =>
            priceStatic.partNumber.toLowerCase().includes(this.partNumberFilter.toLowerCase()),
          ) ||
          priceList.trustedSeries.some((series) =>
            series.partNumber.toLowerCase().includes(this.partNumberFilter.toLowerCase()),
          );

        const matchesExpiration = this.showExpired || new Date(priceList.endDate) >= now;

        return matchesPartner && matchesPartNumber && matchesExpiration;
      });
    },
  },
  methods: {
    async fetchPriceLists() {
      try {
        const data = await getPriceLists({ attributes: ['id', 'name', 'active', 'relationshipSegment'] });
        this.priceLists = data;
      } catch (error) {
        dispatchToast('Error fetching Price Lists', { type: 'error' });
      }
    },
    editItem(item) {
      this.selectedPriceList = item;
      this.showTable = false;
    },
    async handleSubmit(form) {
      this.showTable = true;
      await this.fetchPriceLists();
    },
    handleCancel() {
      this.showTable = true;
    },
    deleteItem(item) {
      this.itemToDelete = item;
      this.showDeleteConfirmation = true;
    },
    async confirmDelete() {
      try {
        await deletePriceList(this.itemToDelete.id);
        dispatchToast('Price List deleted successfully', { type: 'success' });
        await this.fetchPriceLists();
      } catch (error) {
        dispatchToast('Error deleting Price List', { type: 'error' });
      } finally {
        this.showDeleteConfirmation = false;
      }
    },

    cancelDelete() {
      this.showDeleteConfirmation = false;
    },
    createNewPriceList() {
      this.selectedPriceList = {};
      this.showTable = false;
    },
    async exportPriceList(item) {
      try {
        await exportPriceList(item);
        dispatchToast('Export success', { type: 'success' });
      } catch (error) {
        dispatchToast(error.message, { type: 'error' });
      } finally {
      }
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    },
    isExpired(dateString) {
      return new Date(dateString) < new Date();
    },
  },
  mounted() {
    this.fetchPriceLists();
  },
};
</script>

<style scoped>
.expired {
  background-color: #eeeeee;
}
</style>
