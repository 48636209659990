<template>
  <span>
    <span>{{ value }}</span>
    <button class="h-8 mx-2" @click="onEdit">
      <i class="fa-regular fa-pen-to-square"></i>
    </button>
  </span>
</template>

<script>
import { computed } from '@vue/reactivity';

export default {
  setup(props) {
    const value = computed(() => props.params?.value);

    const onEdit = () => {
      const startEditingParams = {
        rowIndex: props.params.rowIndex,
        colKey: props.params.column.getId(),
      };
      props.params.api.startEditingCell(startEditingParams);
    };

    return {
      value,
      onEdit,
    };
  },
};
</script>
