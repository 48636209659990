const saleHistoryGetters = {
  sameProduct(state: any) {
    return state.sameProduct;
  },
  isSearched(state: any) {
    return state.isSearched;
  },
  partNumber(state: any) {
    return state.partNumber;
  },
  oldPartNumber(state: any) {
    return state.oldPartNumber;
  },
  selectedCustomers(state: any) {
    return state.selectedCustomers;
  },
  customersList(state: any) {
    return state.customersList;
  },
  toggleValue(state: any) {
    return state.toggleValue;
  },
  sameProductsData(state: any) {
    const sameProductsData = {
      tabs: [
        {
          title: 'Same Products',
          isActive: state.sameProduct.toggleValue,
          totalResults: state.sameProduct.pagination?.totalResults,
          mutationOnTabClick: 'setSameProduct',
        },
      ],
      data: [state.sameProduct],
    };
    return sameProductsData;
  },
};

export default saleHistoryGetters;
