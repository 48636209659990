<template>
  <section class="page-404 absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%]">
    <div class="center h-full flex items-center justify-center flex-col">
      <div class="error flex flex-row justify justify-between content-center">
        <div class="number font-black text-[15rem] leading-none text-primary">4</div>
        <div class="illustration relative w-[12.2rem]">
          <div class="circle absolute bottom-0 left-0 w-[12.2rem] h-[11.4rem] rounded-full bg-[#2d3e52]"></div>
          <div
            class="clip absolute bottom-[0.3rem] left-1/2 translate-x-[-50%] overflow-hidden w-[12.2rem] h-[13rem] rounded-bl-full rounded-br-full"
          >
            <div
              class="paper absolute bottom-[-0.3rem] left-1/2 translate-x-[-50%] w-[9.2rem] h-[12.4rem] bg-white border-4 border-[#2d3e52] rounded-xl"
            >
              <div class="face relative mt-[2.3rem]">
                <div class="eyes relative top-0 left-[2.4rem] w-[4.6rem] h-[0.8rem]">
                  <div class="eye eye-left left-0"></div>
                  <div class="eye eye-right right-0"></div>
                </div>
                <div class="rosyCheeks rosyCheeks-left left-[1.4rem]"></div>
                <div class="rosyCheeks rosyCheeks-right right-[1.4rem]"></div>
                <div
                  class="mouth absolute top-[3.1rem] left-1/2 w-[1.6rem] h-[0.2rem] rounded-md translate-x-[-50%] bg-[#2d3e52]"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="number font-black text-[15rem] leading-none text-primary">4</div>
      </div>

      <div class="text mt-[5rem] non-italic text-2xl font-semibold text-[#2d3e52]">
        Oops. The page you're looking for doesn't exist.
      </div>
      <router-link to="/home">
        <button class="btn summary-button text-white bg-[#2d3e52] hover:bg-[#2d3e52] m-10 p-4 text-sm leading-none" href="#">
          Back to home
        </button>
      </router-link>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.paper::before {
  content: '';
  position: absolute;
  top: -0.7rem;
  right: -0.7rem;
  width: 1.4rem;
  height: 1rem;
  background-color: white;
  border-bottom: 0.3rem solid #2d3e52;
  transform: rotate(45deg);
}
.eye {
  position: absolute;
  bottom: 0;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: #2d3e52;
  animation-name: eye;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes eye {
  0% {
    height: 0.8rem;
  }
  50% {
    height: 0.8rem;
  }
  52% {
    height: 0.1rem;
  }
  54% {
    height: 0.8rem;
  }
  100% {
    height: 0.8rem;
  }
}

.rosyCheeks {
  position: absolute;
  top: 1.6rem;
  width: 1rem;
  height: 0.2rem;
  border-radius: 50%;
  background-color: #fdabaf;
}
</style>
