import { validateCustomers } from '@/utils/helpers';
import { getCustomers } from '@/services/customer.service';
import { getSaleOrderStates } from '@/services/salesOrder.service';

const rootActions = {
  async setAllCustomers(context: any) {
    let customers = [];
    const requestParams = {
      attributes: JSON.stringify(['id', 'name', 'active', 'relationshipSegment']),
    };
    const { data } = await getCustomers(requestParams);
    customers = validateCustomers(data);
    context.commit('salesHistory/setCustomersList', customers);
    context.commit('setAllCustomers', customers);
  },
  async setSaleOrderStates(context: any) {
    const { data } = await getSaleOrderStates();
    context.commit('setSaleOrderStates', data);
  },
};

export default rootActions;
