
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
export default {
  name: 'SidePanel',
  setup() {
    const route = useRoute();

    const activeTab = computed(() => {
      if (route.name === 'sale-order') {
        return 'sale-orders';
      } else {
        return route.name;
      }
    });

    const isSidebarToggled = ref(true);
    const menuItems = [
      {
        to: { name: 'sale-orders' },
        icon: 'fa-solid fa-dollar-sign',
        text: 'RFQs',
      },
      {
        to: { name: 'history' },
        icon: 'fa-solid fa-history',
        text: 'History',
      },
      {
        to: { name: 'simulator' },
        icon: 'fa-solid fa-line-chart',
        text: 'Simulator',
      },
      {
        to: { name: 'price-lists' },
        icon: 'fa-solid fa-list',
        text: 'Price Lists',
      },
    ];
    const onSidebarToggle = () => {
      isSidebarToggled.value = !isSidebarToggled.value;
    };
    return { activeTab, menuItems, onSidebarToggle, isSidebarToggled };
  },
};
