import { DynamicDataObject } from '@/types/shared.types';
import { get, put } from '@/utils/apiClient';

export const getSaleLineHistoryForSameProduct: GetSaleLineHistory = async (params) => {
  const { success, data } = await get(`/sale-lines/same-product`, {
    params,
  });
  if (success) {
    const { results, ...pagination } = data;
    return {
      success,
      data: results.saleLineHistory,
      customers: results.uniquePartners,
      pagination,
    };
  } else {
    return { success, data, pagination: {} };
  }
};

export const getSaleLineHistoryForSimilarProduct = async (params: any) => {
  const { success, data } = await get(`/sale-lines/similar-product`, {
    params,
  });
  if (success) {
    const { results, ...pagination } = data;
    return {
      success,
      similarPartNumbers: results.similarPartNumbers,
      data: results.saleLineHistory,
      customers: results.uniquePartners,
      pagination,
    };
  } else {
    return { success, data, pagination: {} };
  }
};

export const getSaleLineHistoryBySaleOrderName: GetSaleLineHistoryBySaleOrderName = async (saleOrderName) => {
  const { success, data } = await get(`/sale-lines/saleOrderName`, {
    params: { saleOrderName },
  });
  if (success) {
    const { results, ...pagination } = data;
    return {
      success,
      data: results.saleLineHistory,
      customers: results.uniquePartners,
      pagination,
    };
  } else {
    return { success, data, pagination: {} };
  }
};

export const getSaleLineById: GetSaleLineHistoryById = async (saleLineId) => {
  const apiResponse = await get(`/sale-lines/${saleLineId}`);
  return apiResponse;
};

export const updateSaleLine: UpdateSaleLine = async (saleLineId, reqBody) => {
  const apiResponse = await put(`/sale-lines/${saleLineId}`, reqBody);
  return apiResponse;
};

export const getSaleLineHistoryStatisticsForSameProduct: GetSaleLineHistoryStatisticsForSameProduct = async (params) => {
  const { success, data } = await get(`/pricing-statistics/sameProduct`, {
    params,
  });

  return {
    success,
    data,
  };
};

// ======================================= Types and Interfaces =======================================
interface GetSaleLineHistory {
  (params: object): Promise<{
    success: boolean;
    data: any;
    customers?: any;
    pagination: any;
  }>;
}

interface GetSaleLineHistoryBySaleOrderName {
  (saleOrderName: string): Promise<{
    success: boolean;
    data: any;
    customers?: any;
    pagination: any;
  }>;
}

interface GetSaleLineHistoryStatisticsForSameProduct {
  (params: object): Promise<{
    success: boolean;
    data: any;
  }>;
}

interface GetSaleLineHistoryById {
  (saleLineId: string): Promise<{
    success: boolean;
    data: any;
  }>;
}

interface UpdateSaleLine {
  (saleLineId: string, reqBody: UpdateSaleLineReqBody): Promise<{
    success: boolean;
    data: any;
  }>;
}

interface UpdateSaleLineReqBody {
  notes: string;
  suggestedPrice: number;
  perFootAdder: number;
}
