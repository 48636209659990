import { formatSaleLineHistoryForDataGrid } from '@/utils/helpers';
import { getSaleLineHistoryBySaleOrderName, getSaleLineHistoryForSameProduct } from '@/services/pricingHistories.service';

const saleHistoryActions = {
  async historySearch(context: any, { isSaleOrder, partNumber, selectedCustomers }: any) {
    // Reset Values for new Request
    context.commit('setIsSearched', false);
    context.commit('resetAllData');

    // Get histories
    const selectedCustomerIds = selectedCustomers.map((customer: any) => customer.id);
    const initiateRequestState = {
      isSearched: false,
      isLoading: true,
    };
    let apiResponse: {
      success: boolean;
      data: any;
      customers?: any[];
      pagination: any;
    };
    context.commit('setSameProduct', initiateRequestState);
    if (isSaleOrder) {
      apiResponse = await getSaleLineHistoryBySaleOrderName(partNumber);
    } else {
      const requestParams = {
        partNumber: partNumber,
        partnerIds: JSON.stringify(selectedCustomerIds),
      };
      apiResponse = await getSaleLineHistoryForSameProduct(requestParams);
    }
    const { success, data, customers, pagination } = apiResponse;

    const responseData: any = {
      partNumber,
      isSearched: true,
      isLoading: false,
      toggleValue: true,
      data: {},
      pagination,
      error: '',
    };
    // Handle request Response
    if (!success) {
      responseData.error = data.message;
    } else {
      responseData.data = formatSaleLineHistoryForDataGrid(data, 'historySearch');
      // Set the Customers List of a searched part number
      context.commit('setCustomersList', customers);
    }
    context.commit('setSameProduct', responseData);
    context.commit('setIsSearched', true);
  },
};

export default saleHistoryActions;
