<template>
  <div class="saleLine-tabs flex">
    <div
      class="flex group py-2 text-neutral font-semibold select-none bg-gray-200 transition-[background-color] duration-300 hover:bg-gray-300 cursor-pointer first:rounded-tl-lg last:rounded-tr-lg whitespace-nowrap overflow-hidden"
      v-for="(tab, index) in tabs"
      :class="{
        'border-b-[3px] border-b-primary bg-gray-300': tab.isActive,
      }"
      :key="index"
      @click="$emit('tabClick', tab)"
      @mouseenter="hoverIndex = index"
      @mouseleave="hoverIndex = null"
    >
      <div>
        <span class="px-3">
          {{ tab.title }}
          <span class="italic"
            >(<span class="text-primary">{{ tab.totalResults || 0 }}</span> results found)</span
          >
        </span>
      </div>
      <div class="w-1 min-w-1">
        <span
          v-if="isSeparatorVisible(tab.isActive, tabs[index + 1]?.isActive, index === tabs.length - 1)"
          class="text-gray-400 group-hover:hidden"
          :class="{ hidden: hoverIndex === index + 1 }"
          >|</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  props: {
    tabs: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup() {
    const hoverIndex = ref();

    const isSeparatorVisible = (isTabActive, isNextTabActive, isLastTab) => {
      const isActive = isTabActive || isNextTabActive;
      return !isActive && !isLastTab;
    };

    return {
      hoverIndex,
      isSeparatorVisible,
    };
  },
};
</script>
