import { init, track } from '@amplitude/analytics-browser';
import config from '@/config';
import { getLoggedInUserEmail } from './helpers';

class EventTracker {
  constructor() {
    init(config.amplitudeAnalytics.apiKey);
  }

  static calculationSelect(costingType: string) {
    const loggedInUserEmail = getLoggedInUserEmail();

    track('calculationSelect', {
      costingType,
      loggedInUserEmail,
    });
  }

  static simulatorView(payLoad: SimulatorViewPayload) {
    const loggedInUserEmail = getLoggedInUserEmail();

    track('simulatorView', {
      partNumber: payLoad.partNumber || null,
      customers: payLoad.customers ? JSON.stringify(payLoad.customers) : null,
      loggedInUserEmail,
    });
  }

  static calculatorEdit() {
    const loggedInUserEmail = getLoggedInUserEmail();

    track('calculatorEdit', { loggedInUserEmail });
  }

  static calculatorSubmit() {
    const loggedInUserEmail = getLoggedInUserEmail();

    track('calculatorSubmit', { loggedInUserEmail });
  }

  static historySearch(payLoad: HistorySearchPayload) {
    const loggedInUserEmail = getLoggedInUserEmail();

    track('historySearch', {
      partNumber: payLoad.partNumber || null,
      customers: payLoad.customers ? JSON.stringify(payLoad.customers) : null,
      loggedInUserEmail,
    });
  }

  static historyView() {
    const loggedInUserEmail = getLoggedInUserEmail();

    track('historyView', { loggedInUserEmail });
  }

  static orderLineClick(payLoad: OrderLineClickPayload) {
    const loggedInUserEmail = getLoggedInUserEmail();

    track('orderLineClick', {
      partNumber: payLoad.partNumber,
      customers: payLoad.customer,
      loggedInUserEmail,
    });
  }

  static rfqClick() {
    const loggedInUserEmail = getLoggedInUserEmail();

    track('rfqClick', { loggedInUserEmail });
  }

  static rfqLoad(isSuccess: boolean) {
    const loggedInUserEmail = getLoggedInUserEmail();

    track('rfqLoad', {
      success: isSuccess,
      loggedInUserEmail,
    });
  }

  static rfqView() {
    const loggedInUserEmail = getLoggedInUserEmail();

    track('rfqView', { loggedInUserEmail });
  }

  static saleOrderView() {
    const loggedInUserEmail = getLoggedInUserEmail();

    track('saleOrderView', { loggedInUserEmail });
  }

  static simulatorSearch(payLoad: SimulatorSearchPayload) {
    const loggedInUserEmail = getLoggedInUserEmail();

    track('simulatorSearch', {
      partNumber: payLoad.partNumber || null,
      customers: payLoad.customers ? JSON.stringify(payLoad.customers) : null,
      loggedInUserEmail,
    });
  }

  static postPrices(saleOrderName: string) {
    const loggedInUserEmail = getLoggedInUserEmail();

    track('postPrices', {
      saleOrderName,
      loggedInUserEmail,
    });
  }

  static cellEdit(saleOrderName: string, partNumber: string, column: string) {
    const loggedInUserEmail = getLoggedInUserEmail();

    track('cellEdit', {
      saleOrderName,
      partNumber,
      column,
      loggedInUserEmail,
    });
  }
}

interface SimulatorViewPayload {
  partNumber?: string | null;
  customers?: string[] | null;
}

type SimulatorSearchPayload = SimulatorViewPayload;

type HistorySearchPayload = SimulatorViewPayload;

interface OrderLineClickPayload {
  partNumber?: string | null;
  customer?: string | null;
}

export default EventTracker;
