<template>
  <div
    class="saleOrders-vue-wrapper shadow-md border border-gray-200 mr-5 m-4 mt-2.5 bg-white w-[85vw] sm:w-[88.5vw] md:w-auto"
  >
    <div class="saleOrders-header flex items-center h-12 p-3 border-b bg-gray-200">
      <p class="font-semibold text-neutral text-base">RFQs</p>
      <div class="divider divider-horizontal"></div>
      <AppSelect v-model="pricingStatus" :options="pricingStatuses" />
      <div class="divider divider-horizontal"></div>
      <button
        :disabled="isRFQLoading"
        @click="onLoadRFQ"
        :class="[
          'btn btn-neutral btn-xs text-white disabled:text-neutral-content h-7 px-2 rounded-md normal-case',
          { loading: isRFQLoading },
        ]"
      >
        <span v-if="!isRFQLoading" class="mr-2">
          <i class="fa-solid fa-download"></i>
        </span>
        <span class="font-semibold">Load Latest RFQs</span>
      </button>
    </div>
    <div class="tabContainer tablesWrapper mb-4 p-5 max-w-[905px]">
      <!-- =============== Feedback Message =============== -->
      <div v-if="isFeedbackMessageComputed" class="p-5 py-10 border border-gray-200">
        <div
          v-if="saleOrdersDataComputed.isLoading"
          class="flex flex-row text-center text-primary justify-center items-center"
        >
          <i class="fa-solid fa-arrows-rotate"></i>
          <p class="ml-2 mb-[0.5px] font-semibold">Searching for Pending Sale Orders...</p>
        </div>
        <div
          class="flex flex-row text-center justify-center items-center text-red-500"
          v-else-if="saleOrdersDataComputed.error.length"
        >
          <i class="fa-solid fa-circle-exclamation"></i>
          <p class="ml-2 mb-[0.5px] font-semibold">
            {{ saleOrdersDataComputed.error }}
          </p>
        </div>
        <div
          class="flex flex-row text-center justify-center items-center text-primary"
          v-else-if="!Object.keys(saleOrdersDataComputed.data).length"
        >
          <i class="fa-solid fa-circle-exclamation"></i>
          <p class="ml-2 mb-[0.5px] font-semibold">No pending RFQs Found!</p>
        </div>
      </div>
      <!-- =============== SaleOrders Data =============== -->
      <div v-if="!isFeedbackMessageComputed && Object.keys(saleOrdersDataComputed.data).length">
        <DataGrid
          domLayout="autoHeight"
          :columnsDefs="saleOrdersDataComputed.data.columnsDefs"
          :rowsData="saleOrdersDataComputed.data.rowsData"
          :rowClass="['cursor-pointer']"
          @rowClicked="onClickRow"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DataGrid from '@/components/DataGrid/DataGrid.vue';
import AppSelect from '@/components/AppSelect.vue';
import { useStore } from 'vuex';
import { ref, watch, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import * as databaseSyncServices from '../services/databaseSync.service';
import { dispatchToast } from '@/utils/dispatchToast';
import EventTracker from '@/utils/EventTracker';

export default {
  name: 'SaleOrdersView',
  components: {
    DataGrid,
    AppSelect,
  },
  setup() {
    const pricingStatuses = [
      { name: 'RFQ', value: 'RFQ' },
      { name: 'Posted', value: 'Posted' },
      { name: 'All', value: 'All' },
    ];
    const pricingStatus = ref(pricingStatuses[0].value);

    const store = useStore();
    const router = useRouter();

    const isRFQLoading = ref(false);

    // ========================= Lifecycle hooks =========================
    onMounted(() => {
      EventTracker.rfqView();
      store.dispatch('saleOrders/saleOrders', pricingStatus.value);
    });

    // ========================= Watchers =========================
    watch(pricingStatus, () => {
      store.dispatch('saleOrders/saleOrders', pricingStatus.value);
    });

    const saleOrdersDataComputed = computed(() => store.getters['saleOrders/saleOrders']);

    const isFeedbackMessageComputed = computed(
      () =>
        saleOrdersDataComputed.value.isLoading ||
        saleOrdersDataComputed.value.error.length ||
        !Object.keys(saleOrdersDataComputed.value.data).length,
    );

    // ========================= Methods =========================
    const onClickRow = (event) => {
      const clickedRow = event.data;
      const clickedCell = event.api.getFocusedCell();

      if (['pricingStatus'].includes(clickedCell.column.colId)) {
        return;
      }

      EventTracker.rfqClick();

      router.push({
        name: 'sale-order',
        params: { saleOrderId: clickedRow.id },
      });
    };

    const onLoadRFQ = async () => {
      isRFQLoading.value = true;

      const { success, data } = await databaseSyncServices.databaseSyncHourly();

      if (!success) {
        dispatchToast(data.message, {
          type: 'error',
          timeout: false,
          draggable: false,
          closeOnClick: false,
        });
      } else {
        dispatchToast('Records Updated Successfully!', { type: 'success' });
      }

      EventTracker.rfqLoad(success);

      store.dispatch('saleOrders/saleOrders', pricingStatus.value);

      isRFQLoading.value = false;
    };

    return {
      isRFQLoading,
      pricingStatus,
      pricingStatuses,
      isFeedbackMessageComputed,
      saleOrdersDataComputed,
      onLoadRFQ,
      onClickRow,
    };
  },
};
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 500px) {
  .saleOrders-vue-wrapper {
    width: 81.5vw;
  }
}
</style>
