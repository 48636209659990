import { sameProductInitialValue } from './saleHistory.store';

const saleHistoryMutations = {
  resetAllValues(state: any, payload: any) {
    state.isSearched = false;
    state.partNumber = '';
    state.oldPartNumber = '';
    state.selectedCustomers = [];
    state.customersList = payload.allCustomers;
    state.toggleValue = false;
  },
  resetAllData(state: any) {
    Object.assign(state.sameProduct, sameProductInitialValue);
  },
  setPartNumber(state: any, value: any) {
    state.partNumber = value;
  },
  setOldPartNumber(state: any, value: any) {
    state.oldPartNumber = value;
  },
  setIsSearched(state: any, value: any) {
    state.isSearched = value;
  },
  setSelectedCustomers(state: any, value: any) {
    state.selectedCustomers = value;
  },
  setCustomersList(state: any, value: any) {
    state.customersList = value;
  },
  setSameProduct(state: any, value: any) {
    Object.assign(state.sameProduct, value);
  },
};

export default saleHistoryMutations;
