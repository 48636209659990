import { formatSaleOrdersForDataGrid } from '@/utils/helpers';
import { getSaleOrders } from '@/services/salesOrder.service';

const saleOrdersActions = {
  async saleOrders(context: any, pricingStatus: string) {
    // Reset Values for new Request

    context.commit('setIsSearched', false);

    const initiateRequestState = {
      isSearched: false,
      isLoading: true,
    };
    context.commit('setSaleOrders', initiateRequestState);

    const apiResponse = await getSaleOrders(pricingStatus);

    const { success, data } = apiResponse;

    const responseData: any = {
      isSearched: true,
      isLoading: false,
      toggleValue: true,
      data: {},
      error: '',
    };

    // Handle request Response
    if (!success) {
      responseData.error = data.message;
    } else {
      responseData.data = formatSaleOrdersForDataGrid(data);
    }

    context.commit('setSaleOrders', responseData);
    context.commit('setIsSearched', true);
  },
};

export default saleOrdersActions;
