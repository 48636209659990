<template>
  <span>{{ createDate }}</span>
</template>

<script>
import { dateTimeFormat } from '@/utils/helpers';
import { computed } from '@vue/reactivity';

export default {
  setup(props) {
    const createDate = computed(() => {
      return dateTimeFormat(props.params?.value, 'MMMM, YYYY');
    });
    return {
      createDate,
    };
  },
};
</script>
