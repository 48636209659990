type LengthUnit = 'CM' | 'I' | 'F' | 'M';

const conversions: { [key in LengthUnit]: number } = {
  CM: 1, // 1 centimeter
  I: 2.54, // 1 inch is 2.54 centimeters
  F: 30.48, // 1 foot is 30.48 centimeters
  M: 100, // 1 meter is 100 centimeters
};

function convertLength(value: number, from: LengthUnit, to: LengthUnit): number {
  // Convert the value from the source unit to centimeters
  const valueInCM = value * conversions[from];

  // Convert the value in centimeters to the destination unit
  return valueInCM / conversions[to];
}

export { convertLength, LengthUnit };
