import { concatArraysOfObjects } from '@/utils/helpers';
import {
  sameProductInitialValue,
  similarProductsInitialValue,
  sameCustomerSameProductInitialValue,
  sameCustomerSimilarProductsInitialValue,
  similarCustomersSameProductInitialValue,
  similarCustomersSimilarProductsInitialValue,
  statisticsInitialValue,
} from './simulator.store';

const simulatorMutations = {
  resetAllValues(state: any, payload: any) {
    state.isSearched = false;
    state.partNumber = '';
    state.oldPartNumber = '';
    state.selectedCustomers = [];
    state.customersList = payload.allCustomers;
    state.toggleValue = false;
    state.saleLineToPrice = {};
  },
  setPartNumber(state: any, value: any) {
    state.partNumber = value;
  },
  setOldPartNumber(state: any, value: any) {
    state.oldPartNumber = value;
  },
  setIsSearched(state: any, value: any) {
    state.isSearched = value;
  },
  setSelectedCustomers(state: any, value: any) {
    state.selectedCustomers = value;
  },
  setCustomersList(state: any, value: any) {
    state.customersList = value;
  },
  setSaleLineToPrice(state: any, value: any) {
    Object.assign(state.saleLineToPrice, value);
  },
  appendCustomersList(state: any, value: any) {
    state.customersList = concatArraysOfObjects(state.customersList, value, 'id');
  },
  resetAllData(state: any) {
    Object.assign(state.sameProduct, sameProductInitialValue);
    Object.assign(state.similarProducts, similarProductsInitialValue);
    Object.assign(state.sameCustomerSameProduct, sameCustomerSameProductInitialValue);
    Object.assign(state.sameCustomerSimilarProducts, sameCustomerSimilarProductsInitialValue);
    Object.assign(state.similarCustomersSameProduct, similarCustomersSameProductInitialValue);
    Object.assign(state.similarCustomersSimilarProducts, similarCustomersSimilarProductsInitialValue);
    Object.assign(state.statistics.sameCustomerSameProduct, statisticsInitialValue);
    state.isSearched = false;
  },
  setSameProduct(state: any, value: any) {
    Object.assign(state.sameProduct, value);
  },
  setSimilarProducts(state: any, value: any) {
    Object.assign(state.similarProducts, value);
  },
  setSameCustomerSameProduct(state: any, value: any) {
    Object.assign(state.sameCustomerSameProduct, value);
  },
  setSameCustomerSimilarProducts(state: any, value: any) {
    Object.assign(state.sameCustomerSimilarProducts, value);
  },
  setSimilarCustomersSameProduct(state: any, value: any) {
    Object.assign(state.similarCustomersSameProduct, value);
  },
  setSimilarCustomersSimilarProducts(state: any, value: any) {
    Object.assign(state.similarCustomersSimilarProducts, value);
  },
  setStatisticsForSameCustomerSameProduct(state: any, value: any) {
    Object.assign(state.statistics.sameCustomerSameProduct, value);
  },
  resetToggleValueForSameSimilar(state: any) {
    state.sameProduct.toggleValue = false;
    state.similarProducts.toggleValue = false;
  },
  resetToggleValueForTwoByTwoMatrix(state: any) {
    state.sameCustomerSameProduct.toggleValue = false;
    state.sameCustomerSimilarProducts.toggleValue = false;
    state.similarCustomersSameProduct.toggleValue = false;
    state.similarCustomersSimilarProducts.toggleValue = false;
  },
};

export default simulatorMutations;
