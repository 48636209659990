const config = {
  env: process.env.NODE_ENV,
  port: process.env.VUE_APP_DEV_PORT,
  baseUrls: {
    client: process.env.BASE_URL,
    api: process.env.VUE_APP_API_BASE_URL,
    erp: process.env.VUE_APP_ERP_BASE_URL,
  },
  auth0: {
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE_URL,
  },
  amplitudeAnalytics: {
    apiKey: process.env.VUE_APP_AMPLITUDE_API_KEY,
  },
};

export default config;
