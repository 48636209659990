
import { useStore } from 'vuex';
import Input from '@/components/Input.vue';
import CustomerSelect from '@/components/CustomerSelect.vue';
import HistoryTabsContainer from '@/components/views/history/HistoryTabsContainer.vue';
import { ref, computed, onMounted } from 'vue';
import { searchInSimulator } from '@/utils/helpers';
import EventTracker from '@/utils/EventTracker';
import { DynamicDataObject } from '@/types/shared.types';

export default {
  name: 'SaleHistoryView',
  components: {
    Input,
    CustomerSelect,
    HistoryTabsContainer,
  },
  setup() {
    onMounted(() => {
      EventTracker.historyView();
    });

    const store = useStore();
    const multiselectOptions = {
      placeholder: 'Search customer',
      trackBy: 'id',
      searchable: true,
      closeOnSelect: true,
    };
    const isCustomerSelected = ref(true);
    const allCustomers: any = computed(() => store.getters.allCustomers);
    const isSearched = computed(() => store.getters['salesHistory/isSearched']);
    const partNumber = computed(() => store.getters['salesHistory/partNumber']);
    const oldPartNumber = computed(() => store.getters['salesHistory/oldPartNumber']);
    const customersList = computed(() => store.getters['salesHistory/customersList']);
    const selectedCustomers = computed(() => store.getters['salesHistory/selectedCustomers']);
    const saleLineHistoryData = computed(() => store.getters['salesHistory/sameProduct']);

    // ==================== HistoryTabsContainer Data ====================
    const sameProductsData = computed(() => store.getters['salesHistory/sameProductsData']);

    // ==================== Methods ====================

    const onPartNumberInput = (event: any) => {
      store.commit('salesHistory/setPartNumber', event.target.value.trim());
    };

    const onSearchReset = async () => {
      isCustomerSelected.value = false;
      store.commit('salesHistory/resetAllValues', {
        allCustomers: allCustomers.value,
      });
      store.commit('salesHistory/resetAllData');
    };

    const onSearchText = async (event: any) => {
      const isPartNumberSearched = event.key === 'Enter' || event.type === 'click';
      const isFilterUpdated = event.type === 'updateFilter';

      if (isPartNumberSearched || isFilterUpdated) {
        const isSaleOrder = partNumber.value.startsWith('#');
        // Set partNumber value for Request if input changed
        if (isPartNumberSearched && oldPartNumber.value !== partNumber.value) {
          let formattedPartNumber = partNumber.value;
          if (isSaleOrder) {
            formattedPartNumber = formattedPartNumber.split('#')[1];
          }
          store.commit('salesHistory/setOldPartNumber', formattedPartNumber);
        }
        // Reset all values and Return if no input is selected
        if (!oldPartNumber.value && !selectedCustomers.value.length) {
          onSearchReset();
          return;
        }

        // Amplitude Event
        const selectedCustomersNames = selectedCustomers.value?.map((customer: DynamicDataObject) => customer.name);
        EventTracker.historySearch({
          partNumber: partNumber.value || null,
          customers: selectedCustomersNames || null,
        });

        // Get histories
        store.dispatch('salesHistory/historySearch', {
          isSaleOrder,
          partNumber: oldPartNumber.value.toUpperCase(),
          selectedCustomers: selectedCustomers.value,
        });
      }
    };

    const onCustomerNameChange = (customersInfo: any) => {
      const customers = customersInfo.map((item: any) => item);
      store.commit('salesHistory/setSelectedCustomers', customers);
      onSearchText(new Event('updateFilter'));
    };

    const onCellClick = (event: any) => {
      if (event?.colDef?.field === 'partNumber') {
        searchInSimulator(event?.data?.partNumber, event?.data?.customerId);
      }
    };

    return {
      partNumber,
      oldPartNumber,
      isSearched,
      customersList,
      selectedCustomers,
      multiselectOptions,
      isCustomerSelected,
      saleLineHistoryData,
      sameProductsData,
      onCellClick,
      searchInSimulator,
      onPartNumberInput,
      onSearchText,
      onCustomerNameChange,
      onSearchReset,
    };
  },
};
