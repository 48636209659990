import { useToast } from 'vue-toastification';
import CopyableToast from '../components/CopyableToast.vue';

const toast = useToast();
const defaultToastOptions = {
  type: 'default',
  position: 'top-right',
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false,
};

export const dispatchToast = (content: any, options?: any) => {
  const toastOptions = {
    ...defaultToastOptions,
    ...options,
  };
  toast(content, toastOptions);
};

export const dispatchCopyableToast = (message: any, options?: any) => {
  if (!message.includes(':')) {
    dispatchToast(message, options);
    return;
  }
  const partNumber = message.split(':')[1].split(',')[0];
  toast(
    {
      component: CopyableToast,
      props: {
        message,
        partNumber,
      },
      listeners: {
        copied: () => toast.success('Message copied to clipboard!'),
      },
    },
    {
      timeout: false,
      closeOnClick: false,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: 'button',
      icon: true,
      rtl: false,
      ...options,
    },
  );
};
