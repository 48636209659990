<template>
  <div v-if="Object.keys(saleLineToPrice).includes('customerDefaultCode')">
    <div
      class="border border-gray-300 m-4 px-3 rounded-lg duration-500"
      tabindex="0"
      :class="['collapse', isPricingSectionCollapsed ? 'collapse-close' : 'collapse-open']"
    >
      <div class="collapse-title pr-4">
        <div class="flex">
          <label class="btn btn-xs btn-ghost btn-circle mr-1.5" @click="onPricingSectionCollapse">
            <span v-if="isPricingSectionCollapsed">
              <i class="fa-solid fa-caret-right text-base"></i>
            </span>
            <span v-else>
              <i class="fa-solid fa-caret-down text-base"></i>
            </span>
          </label>
          <p class="text-lg text-neutral font-semibold">
            Pricing for "<span class="text-primary italic">{{ saleLineToPrice.customerDefaultCode }}</span
            >" in
            <span class="text-primary">{{ saleLineToPrice.orderId[1] }}</span>
          </p>
          <div class="divider divider-horizontal"></div>
          <v-btn-toggle v-model="pricingTypeSelected" rounded="5" mandatory class="bg-grey-lighten-4">
            <v-btn v-for="option in pricingTypes" :key="option.value" :value="option.value">
              {{ option.name }}
            </v-btn>
          </v-btn-toggle>
        </div>
        <div class="divider m-0"></div>
      </div>
      <div class="collapse-content">
        <div name="pricing-input-group">
          <div v-if="isCostBasedPricing">
            <CostBasedForm :totalCost="totalCost" />
          </div>
          <div v-else>
            <SeriesBasedForm :totalCost="totalCost" />
          </div>
        </div>
      </div>
    </div>

    <div class="divider p-5"></div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import CostBasedForm from '@/components/views/simulator/CostBasedForm.vue';
import SeriesBasedForm from '@/components/views/simulator/SeriesBasedForm.vue';
import { ref, computed, watch } from 'vue';
import EventTracker from '@/utils/EventTracker';
import { VBtnToggle, VBtn } from 'vuetify/components';

export default {
  name: 'PricingForm',
  components: {
    CostBasedForm,
    SeriesBasedForm,
    VBtn,
    VBtnToggle,
  },
  props: {
    totalCost: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup() {
    const store = useStore();
    const saleLineToPrice = computed(() => store.getters['simulator/saleLineToPrice']);

    const pricingTypes = [
      { name: 'Series Based', value: 'Series Based' },
      { name: 'Cost Based', value: 'Cost Based' },
    ];

    const pricingType = ref();
    const pricingTypeSelected = computed({
      get: () => pricingType.value || saleLineToPrice.value.pricingType || 'Series Based',
      set: (val) => {
        pricingType.value = val;
      },
    });
    const isPricingSectionCollapsed = ref(false);
    const isCostBasedPricing = computed(() => pricingTypeSelected.value === 'Cost Based');

    watch(pricingTypeSelected, (newValue) => {
      onPricingTypeChange(newValue);
    });

    /**
     * ===========================================================
     * ========================= Methods =========================
     * ===========================================================
     */
    const onPricingSectionCollapse = () => {
      isPricingSectionCollapsed.value = !isPricingSectionCollapsed.value;
    };

    const onPricingTypeChange = (value) => {
      pricingTypeSelected.value = value;
      store.commit('simulator/setSaleLineToPrice', {
        ...saleLineToPrice.value,
        pricingType: value,
      });
      EventTracker.calculationSelect(value);
    };

    return {
      pricingTypes,
      pricingTypeSelected,
      isCostBasedPricing,
      isPricingSectionCollapsed,
      saleLineToPrice,
      onPricingTypeChange,
      onPricingSectionCollapse,
    };
  },
};
</script>
