<template>
  <div :class="containerClassesComputed">
    <label
      id="input-label"
      v-if="label && labelPosition"
      :for="inputId"
      class="label whitespace-nowrap"
      :class="labelClassesComputed"
      >{{ label }}<span v-if="required" class="input-required-icon">*</span></label
    >
    <input
      v-two-decimal
      :id="inputId"
      :name="name"
      :class="['input-default', inputClassesComputed]"
      :placeholder="placeholder"
      :disabled="disabled"
      :type="type"
      :step="type === 'number' ? step : null"
      :min="type === 'number' ? min : null"
      :max="type === 'number' ? max : null"
      :value="modelValue"
      :lang="lang"
      @input="$emit('update:modelValue', $event?.target?.value)"
    />
    <div name="validation-messages">
      <span v-if="isInputInValidComputed && errorMessage" class="text-xs text-red-500 leading-none">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
export default {
  name: 'PricingFormInputComponent',
  emits: ['update:modelValue'],
  props: {
    type: String,
    name: String,
    isValid: {
      type: Boolean,
      default: null,
    },
    errorMessage: String,
    placeholder: String,
    required: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    label: String,
    step: String,
    min: Number,
    max: Number,
    labelPosition: {
      type: String,
      default: 'top-left',
    },
    lang: {
      type: String,
      default: 'en-US',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
  },
  setup(props) {
    const inputId = ref('');

    onMounted(() => {
      inputId.value = Math.random().toString();
    });

    const containerClassesComputed = computed(() => [
      'flex flex-1',
      {
        'flex-col': !['left', 'right'].includes(props.labelPosition),
        'flex-row': ['left', 'right'].includes(props.labelPosition),
      },
    ]);
    const labelClassesComputed = computed(() => [
      {
        'order-2': ['right', 'bottom-left', 'bottom-right'].includes(props.labelPosition),
        [`input-label-position-${props.labelPosition}`]: props.labelPosition,
      },
    ]);

    const isInputInValidComputed = computed(() => props.isValid === false);

    const inputClassesComputed = computed(() => {
      return [
        {
          'input-danger': isInputInValidComputed.value,
        },
      ];
    });

    return {
      inputId,
      containerClassesComputed,
      labelClassesComputed,
      isInputInValidComputed,
      inputClassesComputed,
    };
  },
};
</script>
<style scoped>
/* ============== Label Positioning Classes ============== */
.input-label-position-top-right,
.input-label-position-right,
.input-label-position-bottom-right {
  @apply self-end ml-5;
}

.input-label-position-left,
.input-label-position-top-left,
.input-label-position-bottom-left {
  @apply mr-5 self-start;
}
.input-default {
  @apply input input-bordered w-full border-2 border-gray-200 focus:border-gray-300 bg-gray-50 disabled:bg-gray-100 rounded-md focus:outline-none;
}

.input-label-position-left,
.input-label-position-right {
  @apply self-center;
}

/* ============== Validation Classes ============== */
.input-required-icon {
  @apply text-lg leading-[0] text-primary;
}

.input-danger {
  @apply border-2 border-red-500 focus:border-red-500 disabled:border-red-500;
}
</style>
