
export default {
  name: 'InputComponent',
  props: {
    placeholder: String,
    modelValue: {
      type: String,
      default: '',
    },
  },
};
