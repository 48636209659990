import moment from 'moment-timezone';
import { DynamicDataObject } from '@/types/shared.types';
import { auth0 } from '@/main';

export const dateTimeFormat = (date: moment.MomentInput, format = 'ddd, DD MMM hh:mm A'): string => {
  const formattedDate = moment(date).format(format);
  return formattedDate;
};

export function convertCamelCaseToTitleCase(str: string): string {
  const result = str.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

export const concatArraysOfObjects = (arr1, arr2, property) => {
  const propertyOfAllObjects = new Set(arr1.map((obj) => obj[property]));
  const merged = [...arr1, ...arr2.filter((obj) => !propertyOfAllObjects.has(obj[property]))];
  return merged;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortObjectsByProperty = (array: Array<any> = [], sortBy: string, order = 'ASC') => {
  if (order == 'ASC') {
    return array?.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1));
  } else if (order == 'DESC') {
    return array?.sort((a, b) => (a[sortBy] > b[sortBy] ? -1 : 1));
  } else {
    return array;
  }
};

export const currencyFormatter = (value: string | number, identifier = '$', format = 'en-US') => {
  let formattedValue = value ?? '';
  if (formattedValue === 0 || formattedValue) {
    const localeValue = formattedValue.toLocaleString(format);
    const valueWithCurrencyIdentifier = `${identifier}${localeValue}`;
    formattedValue = valueWithCurrencyIdentifier;
  }
  return formattedValue;
};

export const getLoggedInUserEmail = (): string => {
  let email = '';

  if (auth0?.user?.value?.email) {
    email = auth0.user.value.email;
  }

  return email;
};
/* =====================================================================================================================
 * ============================================ Api Client Specific Helpers ============================================
 * =====================================================================================================================
 */

export function isRequestToTheServer(data: object | any) {
  const responseKeys = Object.keys(data);
  const hasOnlyTwoKeys = responseKeys.length === 2;
  const hasSuccessAndDataKeys = responseKeys.includes('success') && responseKeys.includes('data');
  const isToServer = hasOnlyTwoKeys && hasSuccessAndDataKeys;
  return isToServer;
}

/* =====================================================================================================================
 * =========================================== Data Formatters for Data Grid ===========================================
 * =====================================================================================================================
 */

import PartNumber from '@/components/DataGrid/Templates/PartNumber.vue';
import OrderId from '@/components/DataGrid/Templates/OrderId.vue';
import TagDisplay from '@/components/DataGrid/Templates/TagDisplay.vue';
import CreateDate from '@/components/DataGrid/Templates/CreateDate.vue';
import PricingStatus from '@/components/DataGrid/Templates/PricingStatus.vue';
import { updateSaleOrder } from '@/services/salesOrder.service';
import { dispatchToast } from '@/utils/dispatchToast';
import store from '@/store/store';
import router from '@/router';
import { computed } from 'vue';

export const formatSaleLineHistoryForDataGrid = (records: any, dataType: string) => {
  if (!records.length) return {};
  const columnsToShow = [
    'partNumber',
    'schema',
    'orderId',
    'customer',
    'segment',
    'relationshipType',
    'uomQty',
    'createDate',
    'priceUnit',
    'orderState',
  ];

  const formattedTable: any = {};

  // Format Columns
  let colNames: any = [];
  records.forEach((record: any) => {
    colNames.push(Object.keys(record));
  });
  colNames = colNames.flat();
  colNames = new Set(colNames);

  const columnsDefs: any = [];
  columnsToShow.forEach((colName: any) => {
    const currentColDef: any = {
      headerName: convertCamelCaseToTitleCase(colName),
      field: colName,
    };

    switch (colName) {
      case 'partNumber':
        currentColDef['minWidth'] = 350;
        currentColDef['pinned'] = 'left';
        currentColDef['lockPosition'] = 'left';
        currentColDef['cellClass'] = 'locked-col';
        if (dataType === 'historySearch') {
          currentColDef['cellClass'] = ['cursor-pointer', 'hover:bg-slate-200'];
        }
        currentColDef['cellRenderer'] = PartNumber;
        break;
      case 'schema':
        currentColDef['minWidth'] = 120;
        currentColDef['hide'] = true;
        break;
      case 'orderId':
        currentColDef['minWidth'] = 140;
        currentColDef['cellRenderer'] = OrderId;
        break;
      case 'customer':
        currentColDef['minWidth'] = 200;
        break;
      case 'segment':
        currentColDef['minWidth'] = 140;
        currentColDef['cellRenderer'] = TagDisplay;
        break;
      case 'relationshipType':
        currentColDef['minWidth'] = 210;
        currentColDef['cellRenderer'] = TagDisplay;
        break;
      case 'uomQty':
        currentColDef['minWidth'] = 140;
        currentColDef['filter'] = 'agNumberColumnFilter';
        break;
      case 'createDate':
        currentColDef['minWidth'] = 185;
        currentColDef['sortingOrder'] = ['desc', 'asc', null];
        currentColDef['cellRenderer'] = CreateDate;
        break;
      case 'priceUnit':
        currentColDef['minWidth'] = 145;
        currentColDef['filter'] = 'agNumberColumnFilter';
        currentColDef['valueFormatter'] = (params: DynamicDataObject) => currencyFormatter(params.value);
        break;
      case 'orderState':
        currentColDef['minWidth'] = 160;
        break;
      default:
        break;
    }

    columnsDefs.push(currentColDef);
  });

  // Format Rows
  const rowsData: any = [];
  records.forEach((record: any) => {
    const endUser = record?.saleOrder?.endUser ? `(${record.saleOrder.endUser})` : '';
    const updatedRecord: any = {
      id: record?.id,
      partNumber: record?.customerDefaultCode,
      isValid: record?.customerDefaultCodeSchema?.isValid,
      orderId: record?.orderId,
      customer: record?.partner?.name ? `${record.partner.name} ${endUser}` : 'N/A',
      customerId: record?.partner?.id,
      segment: record?.partner?.relationshipSegment || 'N/A',
      relationshipType: record?.partner?.relationshipType || 'N/A',
      uomQty: record?.productUomQty,
      createDate: record?.orderDate,
      priceUnit: record?.unitPrice,
      orderState: record?.orderState,
      lengthData: record?.parsedCableLengthGroup?.lengthInfo,
    };

    rowsData.push(updatedRecord);
  });

  formattedTable['columnsDefs'] = columnsDefs;
  formattedTable['rowsData'] = rowsData;

  return formattedTable;
};

export const formatSaleOrdersForDataGrid = (records: DynamicDataObject[]) => {
  if (!records.length) return {};

  const saleOrderStates = computed(() => store.getters['saleOrderStates']);

  const columnsToShow = ['orderName', 'customer', 'createDate', 'csm', 'pricingStatus'];

  const formattedTable: FormattedSaleOrdersDataForDataGrid = {
    columnsDefs: [],
    rowsData: [],
  };

  const columnsDefs: ColDefForDataGrid[] = [];
  columnsToShow.forEach((colName: string) => {
    const isCsm = colName === 'csm';
    const currentColDef: ColDefForDataGrid = {
      headerName: isCsm ? colName.toUpperCase() : convertCamelCaseToTitleCase(colName),
      field: colName,
    };

    switch (colName) {
      case 'orderName':
        currentColDef['minWidth'] = 140;
        break;
      case 'customer':
        currentColDef['minWidth'] = 225;
        break;
      case 'createDate':
        currentColDef['minWidth'] = 160;
        currentColDef['sortingOrder'] = ['desc', 'asc', null];
        currentColDef['cellRenderer'] = CreateDate;
        break;
      case 'csm':
        currentColDef['minWidth'] = 160;
        break;
      case 'pricingStatus':
        currentColDef['minWidth'] = 160;
        currentColDef['editable'] = true;
        currentColDef['cellRenderer'] = PricingStatus;
        currentColDef['cellClass'] = ['cursor-auto'];
        currentColDef['cellEditorSelector'] = () => {
          return {
            component: 'agSelectCellEditor',
            params: {
              values: saleOrderStates.value,
            },
          };
        };
        currentColDef['valueSetter'] = pricingStatusValueSetter;
        break;
    }

    columnsDefs.push(currentColDef);
  });

  // Format Rows
  const rowsData: SaleOrderRowForDataGrid[] = [];

  records.forEach((record: DynamicDataObject) => {
    const updatedRecord: SaleOrderRowForDataGrid = {
      id: record?.id,
      orderId: record?.erpId,
      orderName: record?.name,
      customer: record?.partner?.name || 'N/A',
      createDate: record?.orderDate,
      csm: record?.partner?.csm,
      pricingStatus: record?.pricingStatus,
    };

    rowsData.push(updatedRecord);
  });

  formattedTable['columnsDefs'] = columnsDefs;
  formattedTable['rowsData'] = rowsData;

  return formattedTable;
};
export function createSaleLineDetails(saleLine: DynamicDataObject): SaleLineDetailsForDataTable {
  const totalCableCost: string | null = canCalculateCableCost(saleLine.cableCost, saleLine.quotedProductLength)
    ? getCableCost(saleLine.cableCost, saleLine.quotedProductLength)
    : null;

  const materialMargin: number | null = canCalculateMargins(
    saleLine.suggestedPrice ?? saleLine.enginePrice,
    totalCableCost,
    saleLine.laborCost,
    saleLine.otherCosts,
  )
    ? getMaterialMargin(
        saleLine.suggestedPrice ?? saleLine.enginePrice,
        totalCableCost ? Number(totalCableCost) : 0,
        saleLine.otherCosts,
      )
    : null;

  const productMargin = canCalculateMargins(
    saleLine.suggestedPrice ?? saleLine.enginePrice,
    totalCableCost,
    saleLine.laborCost,
    saleLine.otherCosts,
  )
    ? getProductMargin(
        saleLine.suggestedPrice ?? saleLine.enginePrice,
        totalCableCost ? Number(totalCableCost) : 0,
        saleLine.otherCosts,
        saleLine.laborCost,
      )
    : null;

  const totalCost = getTotalCost(saleLine.cableCost, saleLine.quotedProductLength, saleLine.laborCost, saleLine.otherCosts);

  const updatedRecord: SaleLineDetailsForDataTable = {
    partNumber: saleLine?.customerDefaultCode ?? '',
    partNumberBase: saleLine?.extraInfo.basePartNumber ?? '',
    familyCode: saleLine?.extraInfo.familyCode ?? '',
    uomQty: saleLine?.productUomQty ?? '',
    pricingType: saleLine?.pricingType ?? '',
    enginePriceType: saleLine?.priceType ?? '',
    totalCableCost: totalCableCost,
    otherCosts: saleLine?.otherCosts ?? '',
    labor: saleLine?.laborCost ?? '',
    perFootAdder: saleLine?.perFootAdder ?? '',
    suggestedPrice: saleLine?.suggestedPrice ?? '',
    enginePrice: saleLine?.enginePrice ?? '',
    odooPrice: saleLine?.unitPrice ?? '',
    notes: saleLine?.notes ?? '',
    customerId: saleLine?.partner?.id ?? '',
    id: saleLine?.id ?? '',
    materialMargin: materialMargin,
    productMargin: productMargin,
    odooCost: saleLine?.erpCost === null ? 'N/A' : `$${saleLine.erpCost.toFixed(2)}`,
    totalCost: totalCost,
    enginePriceTooltip: null,
    erpId: saleLine?.erpId ?? '',
    isHigherLengthThanEverSold: saleLine?.extraInfo.isHigherLengthThanEverSold ?? false,
  };
  if (saleLine?.reference1 && !saleLine?.reference2) {
    const dateObject = new Date(saleLine.reference1.saleOrder.orderDate);
    const formattedDate = dateObject.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const orderStatus = translateOdooSalesOrderStatus(saleLine.reference1.saleOrder.erpPricingStatus);
    updatedRecord.enginePriceTooltip = `Based on ${saleLine.reference1.saleOrder.name}, ${formattedDate} (${orderStatus})`;
  } else if (saleLine?.reference1 && saleLine?.reference2) {
    const dateObject1 = new Date(saleLine.reference1.saleOrder.orderDate);
    const formattedDate1 = dateObject1.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const dateObject2 = new Date(saleLine.reference2.saleOrder.orderDate);
    const formattedDate2 = dateObject2.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const orderStatus1 = translateOdooSalesOrderStatus(saleLine.reference1.saleOrder.erpPricingStatus);
    const orderStatus2 = translateOdooSalesOrderStatus(saleLine.reference2.saleOrder.erpPricingStatus);
    updatedRecord.enginePriceTooltip = `Based on: </br>
    <ul>
    <li>· ${saleLine.reference1.customerDefaultCode} in ${saleLine.reference1.saleOrder.name}, ${formattedDate1} (${orderStatus1})</li>
    <li>· ${saleLine.reference2.customerDefaultCode} in ${saleLine.reference2.saleOrder.name}, ${formattedDate2} (${orderStatus2})</li>
    </ul>`;
  } else if (saleLine?.priceListId) {
    updatedRecord.enginePriceTooltip = `Based on ${saleLine.priceList.name} price list.`;
  }

  return updatedRecord;
}

export const formatSaleLineDetailsForDataGrid = (records: DynamicDataObject[]) => {
  if (!records.length) return {};

  const columnsToShow = [
    'partNumber',
    'uomQty',
    'pricingType',
    'suggestedPrice',
    'odooPrice',
    'odooCost',
    'totalCableCost',
    'otherCosts',
    'labor',
    'materialMargin',
    'productMargin',
    'notes',
  ];

  const formattedTable: FormattedSaleLineDetailsDataForDataGrid = {
    columnsDefs: [],
    rowsData: [],
  };

  const columnsDefs: ColDefForDataGrid[] = [];
  columnsToShow.forEach((colName: string) => {
    const currentColDef: ColDefForDataGrid = {
      headerName: convertCamelCaseToTitleCase(colName),
      field: colName,
    };

    switch (colName) {
      case 'partNumber':
        currentColDef['minWidth'] = 350;
        currentColDef['pinned'] = 'left';
        currentColDef['lockPosition'] = 'left';
        currentColDef['cellClass'] = ['cursor-pointer', 'hover:bg-slate-200', 'locked-col'];
        break;
      case 'uomQty':
        currentColDef['minWidth'] = 120;
        break;
      case 'pricingType':
        currentColDef['minWidth'] = 160;
        break;
      case 'otherCosts':
        currentColDef['minWidth'] = 140;
        currentColDef['valueFormatter'] = (params: DynamicDataObject) => currencyFormatter(params.value);
        break;
      case 'labor':
        currentColDef['minWidth'] = 100;
        currentColDef['valueFormatter'] = (params: DynamicDataObject) => currencyFormatter(params.value);
        break;
      case 'totalCableCost':
        currentColDef['minWidth'] = 180;
        currentColDef['valueFormatter'] = (params: DynamicDataObject) => currencyFormatter(params.value);
        break;
      case 'materialMargin':
        currentColDef['minWidth'] = 180;
        currentColDef['valueFormatter'] = (params: DynamicDataObject) => {
          let formattedValue = params.value ?? '';
          if (formattedValue === 0 || formattedValue) {
            formattedValue = `${formattedValue}%`;
          }
          return formattedValue;
        };
        break;
      case 'productMargin':
        currentColDef['minWidth'] = 180;
        currentColDef['valueFormatter'] = (params: DynamicDataObject) => {
          let formattedValue = params.value ?? '';
          if (formattedValue === 0 || formattedValue) {
            formattedValue = `${formattedValue}%`;
          }
          return formattedValue;
        };
        break;
      case 'suggestedPrice':
        currentColDef['minWidth'] = 180;
        currentColDef['valueFormatter'] = (params: DynamicDataObject) => currencyFormatter(params.value);
        break;
      case 'odooPrice':
        currentColDef['minWidth'] = 180;
        currentColDef['valueFormatter'] = (params: DynamicDataObject) => currencyFormatter(params.value);
        break;
      case 'odooCost':
        currentColDef['minWidth'] = 180;
        break;
      case 'notes':
        currentColDef['minWidth'] = 350;
        break;
    }

    columnsDefs.push(currentColDef);
  });

  // Format Rows
  const rowsData: SaleLineDetailsForDataTable[] = [];

  records.forEach((record: DynamicDataObject) => {
    const row: SaleLineDetailsForDataTable = createSaleLineDetails(record);
    rowsData.push(row);
  });

  formattedTable['columnsDefs'] = columnsDefs;
  formattedTable['rowsData'] = rowsData;

  return formattedTable;
};

function isNumeric(val: number | string | null | undefined): boolean {
  return val !== null && val !== undefined && !isNaN(parseFloat(val + '')) && isFinite(parseFloat(val + ''));
}

function hasNumericValue(val: number | string | null | undefined): boolean {
  return val !== null && val !== undefined && isNumeric(val);
}

export const canCalculateCableCost = (
  cableCost: number | string | null | undefined,
  quotedProductLength: number | string | null | undefined,
): boolean => hasNumericValue(cableCost) && hasNumericValue(quotedProductLength);

export const canCalculateMargins = (
  suggestedPrice: number | string | null | undefined,
  totalCableCost: number | string | null | undefined,
  laborCost: number | string | null | undefined,
  otherCosts: number | string | null | undefined,
): boolean =>
  hasNumericValue(suggestedPrice) &&
  (hasNumericValue(totalCableCost) || hasNumericValue(laborCost) || hasNumericValue(otherCosts));

export const getProductMargin = (
  suggestedPrice: number,
  totalCableCost: number,
  otherCosts: number,
  labor: number,
): number => {
  const difference = suggestedPrice - totalCableCost - otherCosts - labor;
  return Number(((difference / suggestedPrice) * 100).toFixed(2)); // margin in %
};

export const getMaterialMargin = (suggestedPrice: number, totalCableCost: number, otherCosts: number): number => {
  const difference = suggestedPrice - totalCableCost - otherCosts;
  return Number(((difference / suggestedPrice) * 100).toFixed(2)); // margin in %
};

export const getCableCost = (cableCost: number, quotedProductLength: number): string => {
  return (cableCost * quotedProductLength).toFixed(2);
};

export const getTotalCost = (
  cableCost: number | string | null | undefined,
  quotedProductLength: number | string | null | undefined,
  laborCost: number | string | null | undefined,
  otherCosts: number | string | null | undefined,
): number | null => {
  const cableCostValue = canCalculateCableCost(cableCost, quotedProductLength)
    ? Number(getCableCost(Number(cableCost), Number(quotedProductLength)))
    : 0;
  const otherCostsValue = hasNumericValue(otherCosts) ? Number(otherCosts) : 0;
  const laborCostValue = hasNumericValue(laborCost) ? Number(laborCost) : 0;

  const totalCost = cableCostValue + otherCostsValue + laborCostValue;
  return totalCost > 0 ? totalCost : null;
};

const pricingStatusValueSetter = async (params: DynamicDataObject) => {
  const newValue = params.newValue;
  const { saleOrderId, saleOrder } = {
    saleOrderId: params.data.id,
    saleOrder: { pricingStatus: params.newValue },
  };

  const { success, data } = await updateSaleOrder(saleOrderId, saleOrder);

  if (success) {
    params.data.pricingStatus = newValue;
  } else {
    dispatchToast(data.message, { type: 'error' });
  }

  params.api.refreshCells({
    rowNodes: [params.node],
    columns: [params.column],
  });

  return success;
};

export const validateCustomers = (customers: any) => {
  return customers.filter((customer: any) => customer.active && customer.name.trim().length && customer.name !== 'false');
};

export const searchInSimulator = (partNumber: string, customerId = '') => {
  const allCustomers: any = computed(() => store.getters.allCustomers);
  const simulatorOldPartNumber = computed(() => store.getters['simulator/oldPartNumber']);
  const simulatorSelectedCustomers = computed(() => store.getters['simulator/selectedCustomers']);
  const selectedCustomers: any[] = [];
  if (customerId) {
    const customerData = allCustomers.value.find((c: any) => {
      return c.id === customerId;
    });
    if (customerData) {
      selectedCustomers.push(customerData);
    }
  }

  store.commit('simulator/resetAllValues', {
    allCustomers: [],
  });
  store.commit('simulator/setPartNumber', partNumber);
  store.commit('simulator/setOldPartNumber', partNumber);
  store.commit('simulator/setSelectedCustomers', selectedCustomers);
  store.dispatch('simulator/simulatorSearch', {
    partNumber,
    selectedCustomers,
  });

  router.push({ name: 'simulator' });
};

export const storeCustomers = (data: any) => {
  const customers = {
    data,
    storedAt: new Date(),
  };
  localStorage.setItem('customers', JSON.stringify(customers));
};

export const isCustomersListExpired = () => {
  const customers: any = JSON.parse(localStorage.getItem('customers') || '{}');
  let isExpired = true;
  if (customers?.storedAt) {
    const hrsDifference = getTimeDifferenceInHours(new Date(), new Date(customers.storedAt));
    isExpired = hrsDifference > 24;
  }
  return isExpired;
};

function getTimeDifferenceInHours(dt2: any, dt1: any) {
  const diffInSeconds = (dt2.getTime() - dt1.getTime()) / 1000;
  let diffInHours = diffInSeconds / (60 * 60);
  diffInHours = Math.abs(Math.round(diffInHours));

  return diffInHours;
}

export function translateOdooSalesOrderStatus(status: OdooStatus): string {
  const statusMap = {
    cancel: ORDERSTATUS.LOST,
    done: ORDERSTATUS.ORDER_PLACED,
    draft: ORDERSTATUS.QUOTATION,
    draft_quote: ORDERSTATUS.QUOTATION,
    inv: ORDERSTATUS.ORDER_PLACED,
    lost: ORDERSTATUS.LOST,
    partial: ORDERSTATUS.ORDER_PLACED,
    pi: ORDERSTATUS.ORDER_PLACED,
    sale: ORDERSTATUS.ORDER_PLACED,
    sent: ORDERSTATUS.QUOTATION,
    shipped: ORDERSTATUS.ORDER_PLACED,
  };
  return statusMap[status];
}
/* =====================================================================================================================
 * ================================================== Interfaces =======================================================
 * =====================================================================================================================
 */
interface ColDefForDataGrid extends DynamicDataObject {
  headerName: string;
  field: string;
}

export interface VuetifyDataTableHeader {
  key: string;
  title: string;
  colspan?: number;
  rowspan?: number;
  fixed?: boolean;
  align?: 'start' | 'end';
  width?: number;
  minWidth?: string;
  maxWidth?: string;
  sortable?: boolean;
  cellClass?: string | string[];
  formatter?: string;
  fallbackColumn?: string;
  fallbackTooltip?: string;
}

interface FormattedSaleLineDetailsDataForVuetifyDataTable {
  columnsDefs: VuetifyDataTableHeader[];
  rowsData: SaleLineDetailsForDataTable[];
}

interface SaleOrderRowForDataGrid {
  id: string;
  orderId: string;
  orderName: string;
  customer: string;
  createDate: string;
  csm: string;
  pricingStatus: string;
}
interface FormattedSaleOrdersDataForDataGrid {
  columnsDefs: ColDefForDataGrid[];
  rowsData: SaleOrderRowForDataGrid[];
}

export interface SaleLineDetailsForDataTable {
  partNumber: string;
  partNumberBase: string;
  isHigherLengthThanEverSold: boolean;
  familyCode: string;
  uomQty: string;
  pricingType: string;
  enginePriceType: string;
  totalCableCost: string | null;
  otherCosts: string;
  labor: string;
  materialMargin: number | null;
  productMargin: number | null;
  perFootAdder: string;
  suggestedPrice: string;
  enginePrice: string | null;
  enginePriceTooltip: string | null;
  odooPrice: string;
  odooCost: string;
  totalCost: number | null;
  notes: string;
  customerId: string;
  id: string;
  erpId: string;
}
interface FormattedSaleLineDetailsDataForDataGrid {
  columnsDefs: ColDefForDataGrid[];
  rowsData: SaleLineDetailsForDataTable[];
}

type OdooStatus =
  | 'cancel'
  | 'done'
  | 'draft'
  | 'draft_quote'
  | 'inv'
  | 'lost'
  | 'partial'
  | 'pi'
  | 'sale'
  | 'sent'
  | 'shipped';

// Define const for the status in pricing
export const ORDERSTATUS = {
  LOST: 'Lost',
  QUOTATION: 'Quotation',
  ORDER_PLACED: 'Order placed',
};
