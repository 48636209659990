import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store/store';
import './assets/scss/main.scss';
import { helpersPlugin } from './plugins';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas);
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(far);
import { dom } from '@fortawesome/fontawesome-svg-core';
dom.watch();
import Toast, { PluginOptions } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { createAuth0 } from '@auth0/auth0-vue';
import config from '@/config';
import { twoDecimal } from './utils/directives/twoDecimals.directive';

// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.css';

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: '#f48c04',
          secondary: '#363062',
        },
      },
    },
  },
});

const options: PluginOptions = {
  transition: 'Vue-Toastification__fade',
  maxToasts: 5,
  newestOnTop: true,
};

export const auth0 = createAuth0({
  domain: config.auth0.domain,
  client_id: config.auth0.clientId,
  redirect_uri: window.location.origin,
  audience: config.auth0.audience,
});

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .directive('two-decimal', twoDecimal)
  .use(Toast, options)
  .use(store)
  .use(helpersPlugin)
  .use(router)
  .use(auth0)
  .use(vuetify)
  .mount('#app');
