<template>
  <ag-grid-vue
    :class="gridClasses"
    :domLayout="domLayout"
    :rowClass="gridRowClasses"
    :rowClassRules="gridRowClassRules"
    :columnDefs="gridColumnDefs"
    :rowData="gridRowData"
    :defaultColDef="gridDefaultColDef"
    :rowSelection="gridRowSelection"
    :rowMultiSelectWithClick="true"
    :animateRows="true"
    :suppressDragLeaveHidesColumns="true"
    :suppressClickEdit="true"
    :stopEditingWhenCellsLoseFocus="true"
    :enableCellTextSelection="true"
    :ensureDomOrder="true"
    @rowClicked="onRowClicked"
    @cell-clicked="cellWasClicked"
    @selection-changed="onSelectionChanged"
    @grid-ready="onGridReady"
  >
  </ag-grid-vue>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue3'; // the AG Grid Vue Component
import { ref } from 'vue';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

import { computed } from '@vue/reactivity';

export default {
  name: 'App',
  components: {
    AgGridVue,
  },
  emits: ['cellClicked', 'rowSelectionChange', 'rowClicked'],
  props: {
    columnsDefs: {
      type: Array,
      required: true,
    },
    rowsData: {
      type: Array,
      required: true,
    },
    class: {
      type: String,
      default: '',
    },
    rowClassRules: {
      type: Object,
      default: () => ({}),
    },
    defaultColDef: {
      type: Object,
      default: () => ({}),
    },
    rowSelection: {
      type: String,
      default: '',
    },
    domLayout: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const gridClasses = computed(() => {
      return 'ag-theme-alpine ' + props.class;
    });

    const gridRowClasses = computed(() => {
      return props.rowClass;
    });

    const gridRowClassRules = computed(() => {
      return {
        warning: (params) => {
          let partNumberIsValid = true;
          if (params?.data?.isValid !== undefined) {
            partNumberIsValid = params.data.isValid;
          }
          return !partNumberIsValid;
        },
        ...props.rowClassRules,
      };
    });

    // Each Column Definition results in one Column.
    const gridColumnDefs = computed(() => {
      return props.columnsDefs;
    });

    // Set rowData to Array of Objects, one Object per Row
    const gridRowData = computed(() => {
      return props.rowsData;
    });

    // DefaultColDef sets props common to all Columns
    const gridDefaultColDef = computed(() => {
      return {
        sortable: true,
        filter: true,
        flex: 1,
        resizable: true,
        lockPinned: true,
        ...props.defaultColDef,
      };
    });

    const gridRowSelection = computed(() => {
      return props.rowSelection;
    });

    const gridApi = ref(null); // Optional - for accessing Grid's API

    // Obtain API from grid's onGridReady event
    const onGridReady = (params) => {
      gridApi.value = params.api;
    };

    const cellWasClicked = (event) => {
      context.emit('cellClicked', event);
    };

    const onSelectionChanged = (event) => {
      context.emit('rowSelectionChange', event);
    };

    const onRowClicked = (event) => {
      context.emit('rowClicked', event);
    };

    return {
      gridRowSelection,
      gridClasses,
      gridRowClasses,
      gridRowClassRules,
      onGridReady,
      gridColumnDefs,
      gridRowData,
      gridDefaultColDef,
      cellWasClicked,
      onSelectionChanged,
      onRowClicked,
    };
  },
};
</script>

<style lang="scss"></style>
