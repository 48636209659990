const rootMutations = {
  setAllCustomers(state: any, customers: any) {
    state.allCustomers = customers;
  },
  setSaleOrderStates(state: any, states: string[]) {
    state.saleOrderStates = states;
  },
};

export default rootMutations;
