<template>
  <a :href="erpLink" target="_blank" class="text-primary underline cursor-pointer">
    {{ orderId }}
  </a>
</template>

<script>
import { computed } from '@vue/reactivity';
import config from '@/config';

export default {
  setup(props) {
    const isSaleLineOrderId = Array.isArray(props.params?.value) && props.params?.value.length === 2;

    const orderId = computed(() => (isSaleLineOrderId ? props.params?.value[1] : props.params?.value));

    const erpLink = computed(() => {
      const orderLineId = isSaleLineOrderId ? props.params?.value[0] : props.params?.data.orderId;
      return `${config.baseUrls.erp}/web?#id=${orderLineId}&action=484&model=sale.order&view_type=form&cids=1&menu_id=300`;
    });

    return {
      orderId,
      erpLink,
    };
  },
};
</script>

<style scoped></style>
