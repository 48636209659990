import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LoginView from '@/views/LoginView.vue';
import HomeView from '@/views/HomeView.vue';
import SimulatorView from '@/views/SimulatorView.vue';
import SaleHistoryView from '@/views/SaleHistoryView.vue';
import SaleOrdersView from '@/views/SaleOrdersView.vue';
import SaleOrderDetailsView from '@/views/SaleOrderDetailsView.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import ResetTokenExpiredView from '@/views/Auth/ResetTokenExpiredView.vue';
import PriceListsView from '@/views/PriceListsView.vue';
import config from '@/config';
import { authGuard } from '@auth0/auth0-vue';

async function isAuthenticated(to: any, from: any, next: any) {
  let isAuthGuarded = false;
  isAuthGuarded = await authGuard(to);
  if (isAuthGuarded) return next();
  else return next({ name: 'login' });
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: 'home',
    beforeEnter: isAuthenticated,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/auth/reset-token-expired',
    name: 'reset-token-expired',
    component: ResetTokenExpiredView,
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/home/sale-orders',
    components: {
      default: HomeView,
      contentArea: HomeView,
    },
    beforeEnter: isAuthenticated,
    children: [
      {
        path: 'history',
        name: 'history',
        components: {
          default: SaleHistoryView,
          contentArea: SaleHistoryView,
        },
      },
      {
        path: 'simulator',
        name: 'simulator',
        components: {
          default: SimulatorView,
          contentArea: SimulatorView,
        },
      },
      {
        path: 'sale-orders',
        name: 'sale-orders',
        components: {
          default: SaleOrdersView,
          contentArea: SaleOrdersView,
        },
      },
      {
        path: 'sale-orders/:saleOrderId',
        name: 'sale-order',
        components: {
          default: SaleOrderDetailsView,
          contentArea: SaleOrderDetailsView,
        },
      },
      {
        path: 'price-lists',
        name: 'price-lists',
        components: {
          default: PriceListsView,
          contentArea: PriceListsView,
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(config.baseUrls.client),
  routes,
});

export default router;
