import saleOrdersActions from './actions';
import saleOrdersGetters from './getters';
import saleOrdersMutations from './mutations';

export const saleOrdersInitialValue = {
  isSearched: false,
  isLoading: false,
  data: {},
  toggleValue: false,
  pagination: {},
  error: '',
};

const saleOrdersModule = {
  namespaced: true,
  state: {
    isSearched: false,
    saleOrders: {
      ...saleOrdersInitialValue,
    },
  },

  mutations: saleOrdersMutations,
  actions: saleOrdersActions,
  getters: saleOrdersGetters,
};

export default saleOrdersModule;
