<template>
  <div>
    <Header></Header>
    <div class="HomeView h-full flex overflow-hidden">
      <SidePanel />
      <div class="vp-custom-background"></div>
      <div class="contentArea overflow-y-auto overflow-x-hidden">
        <notification
          v-if="updateExists"
          :content="updateNotificationContent(refreshApp)"
          :toastOptions="{
            timeout: false,
            draggable: false,
            closeOnClick: false,
            closeButton: false,
            hideProgressBar: true,
            icon: 'fa-solid fa-circle-info text-primary text-2xl',
          }"
        />
        <RouterView name="contentArea"> </RouterView>
      </div>
    </div>
  </div>
</template>

<script>
import SidePanel from '@/components/SidePanel.vue'; // @ is an alias to /src
import Header from '@/components/Header.vue'; // @ is an alias to /src
import Notification from '../components/layout/Notification.vue';
import UpdateNotification from '../components/slots/UpdateNotification.vue';
import { updateMixin } from '../mixins/update.js';
export default {
  components: { SidePanel, Header, Notification },
  mixins: [updateMixin],

  setup() {
    // Created this method because the mixins' constants/functions couldn't be accessed in script.
    // In this case we have to pass refreshApp method to the Update Notification Component.
    const updateNotificationContent = (refresh) => {
      return {
        component: UpdateNotification,
        props: {
          refresh: refresh,
        },
      };
    };

    return {
      updateNotificationContent,
    };
  },
};
</script>

<style lang="scss" scoped></style>
