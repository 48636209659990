const rootGetters = {
  allCustomers(state: any) {
    return state.allCustomers;
  },
  saleOrderStates(state: any) {
    return state.saleOrderStates;
  },
};

export default rootGetters;
