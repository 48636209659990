<template>
  <div class="confirmation-modal">
    <div class="confirmation-modal-content">
      <h3>{{ title }}</h3>
      <p>{{ message }}</p>
      <div class="modal-buttons">
        <button class="btn btn-sm btn-primary rounded m-3 text-white" @click="confirm">
          {{ confirmButtonText }}
        </button>
        <button class="btn btn-sm rounded m-3" @click="cancel">
          {{ cancelButtonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<style>
.confirmation-modal {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.confirmation-modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
}
.modal-buttons {
  display: flex;
  justify-content: center;
}
.confirmation-modal-content h3 {
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
}
.confirmation-modal-content p {
  text-align: center;
  margin-top: 20px;
}
.confirmation-modal-content button {
  margin-top: 40px;
  color: white;
}
</style>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    confirmButtonText: {
      type: String,
      required: true,
    },
    cancelButtonText: {
      type: String,
      required: true,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
