<template>
  <div class="flex h-28 mb-3">
    <KPITile
      v-for="(stat, index) in data"
      :key="index"
      :title="stat.title"
      :customer="stat.customer"
      :saleLineData="stat.saleLineData"
    />
  </div>
</template>

<script>
import KPITile from '../KPITile.vue';
export default {
  components: {
    KPITile,
  },
  props: {
    data: {
      type: Array,
    },
  },
};
</script>
