import { formatSaleLineHistoryForDataGrid, dateTimeFormat } from '@/utils/helpers';
import {
  getSaleLineHistoryForSameProduct,
  getSaleLineHistoryForSimilarProduct,
  getSaleLineHistoryStatisticsForSameProduct,
  getSaleLineById,
} from '@/services/pricingHistories.service';
import { dispatchToast } from '@/utils/dispatchToast';

const simulatorActions = {
  async simulatorSearch(context: any, { partNumber, selectedCustomers }: any) {
    context.commit('resetAllData');
    if (!selectedCustomers.length) {
      context.commit('setCustomersList', []);
    }

    const commitResponseData = (commitName: any, response: any) => {
      const responseData: any = {
        error: '',
        data: {},
        pagination: response.pagination,
      };
      if (response.success) {
        responseData.data = formatSaleLineHistoryForDataGrid(response.data, 'simulatorSearch');
        if (response.similarPartNumbers) {
          responseData['similarPartNumbers'] = response.similarPartNumbers;
        }
        if (response.toggleValue) {
          responseData['toggleValue'] = response.toggleValue;
        }
        // remove from the response the one that are already in the list
        // If they are children, remove it if the parent is already in the list
        // Remove it from the list if they have the same parent that other in the list
        const customers = response.customers.filter((customer: any) => {
          const isAlreadyInList = context.state.customersList.some((customerInList: any) => {
            const customerInListErpId = customerInList.parent_id?.[0] || customerInList.erpId;
            const customerErpId = customer.parent_id?.[0] || customer.erpId;
            const isSamePresent = customerInList.id === customer.id;
            return isSamePresent || customerInListErpId === customerErpId;
          });
          return !isAlreadyInList;
        });
        context.commit('appendCustomersList', customers);
      } else {
        responseData.error = response.data.message;
      }
      context.commit(commitName, {
        isSearched: true,
        isLoading: false,
        partNumber,
        ...responseData,
      });
    };

    const selectedCustomerIds = selectedCustomers.map((customer: any) => customer.id);
    const requestParams = {
      partNumber,
      partnerIds: JSON.stringify(selectedCustomerIds),
    };
    const initiateRequestState = {
      isSearched: false,
      isLoading: true,
    };
    const isSimilarCustomerSearch = selectedCustomerIds?.length === 1;
    if (isSimilarCustomerSearch) {
      // SameCustomer x SameProduct
      context.commit('setSameCustomerSameProduct', initiateRequestState);
      const sameCustomerSameProduct = await getSaleLineHistoryForSameProduct(requestParams);
      commitResponseData('setSameCustomerSameProduct', {
        ...sameCustomerSameProduct,
        toggleValue: true,
      });

      // SameCustomer x SimilarProducts
      context.commit('setSameCustomerSimilarProducts', initiateRequestState);
      const sameCustomerSimilarProducts = await getSaleLineHistoryForSimilarProduct(requestParams);
      commitResponseData('setSameCustomerSimilarProducts', sameCustomerSimilarProducts);

      // SimilarCustomers x SameProduct
      context.commit('setSimilarCustomersSameProduct', initiateRequestState);
      const similarCustomersSameProduct = await getSaleLineHistoryForSameProduct({
        ...requestParams,
        isSimilarCustomerSearch: true,
      });
      commitResponseData('setSimilarCustomersSameProduct', similarCustomersSameProduct);

      // SimilarCustomers x SimilarProducts
      context.commit('setSimilarCustomersSimilarProducts', initiateRequestState);
      const similarCustomersSimilarProducts = await getSaleLineHistoryForSimilarProduct({
        ...requestParams,
        isSimilarCustomerSearch: true,
      });
      commitResponseData('setSimilarCustomersSimilarProducts', similarCustomersSimilarProducts);

      // Statistics
      context.commit('setStatisticsForSameCustomerSameProduct', initiateRequestState);
      const { success, data } = await getSaleLineHistoryStatisticsForSameProduct(requestParams);

      let response: StatisticsResponse = {
        wins: { latest: {}, highestPrice: {}, lowestPrice: {} },
      };

      if (!success) {
        response.error = data.message;
      } else {
        const { wins } = data;
        response.wins = wins;
        response = formatStatisticsResponse(response);
      }

      context.commit('setStatisticsForSameCustomerSameProduct', {
        isSearched: true,
        isLoading: false,
        partNumber,
        ...response,
      });
    } else {
      // Same Product
      context.commit('setSameProduct', initiateRequestState);
      const sameProducts = await getSaleLineHistoryForSameProduct(requestParams);
      commitResponseData('setSameProduct', {
        ...sameProducts,
        toggleValue: true,
      });

      // Similar Product
      context.commit('setSimilarProducts', initiateRequestState);
      const similarProducts = await getSaleLineHistoryForSimilarProduct(requestParams);
      commitResponseData('setSimilarProducts', similarProducts);
    }

    context.commit('setIsSearched', true);
  },
  async saleLineToPrice(context: any, lineData: DynamicDataObject) {
    context.commit('setSaleLineToPrice', {
      id: lineData.saleLineToPriceId,
      saleOrderId: lineData.saleOrderId,
    });
    const { success, data } = await getSaleLineById(lineData.saleLineToPriceId);
    if (!success) {
      dispatchToast(data.message, { type: 'error' });
    } else {
      context.commit('setSaleLineToPrice', data);
    }
  },
};

// ======================================= Helper Methods =======================================

const formatStatisticsResponse = (statistics: Statistics) => {
  Object.values(statistics).forEach((stat: DynamicDataObject) => {
    Object.values(stat).forEach((entity: DynamicDataObject) => {
      if (Object.keys(entity).length) {
        entity.orderDate = dateTimeFormat(entity.orderDate, 'MMMM DD, YYYY');
      }
    });
  });
  return statistics;
};

// ======================================= Interfaces =======================================

export interface WinsStatistics {
  latest: DynamicDataObject;
  highestPrice: DynamicDataObject;
  lowestPrice: DynamicDataObject;
}

export interface Statistics {
  wins: WinsStatistics;
}

interface StatisticsResponse extends Statistics {
  error?: '';
}

export interface DynamicDataObject {
  [key: string]: any;
}

export default simulatorActions;
