<template>
  <div v-for="(tag, index) in tags" :key="index" class="badge badge-nuetral badge-outline">
    {{ tag }}
  </div>
</template>

<script>
import { computed } from '@vue/reactivity';

export default {
  setup(props) {
    const tags = computed(() => {
      if (typeof props.params?.value == 'object') return props.params?.value;
      else return [props.params?.value];
    });
    return {
      tags,
    };
  },
};
</script>

<style scoped></style>
