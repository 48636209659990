import { createStore } from 'vuex';

import saleOrdersModule from './modules/saleOrders/saleOrders.store';
import saleHistoryModule from './modules/saleHistory/saleHistory.store';
import simulatorModule from './modules/simulator/simulator.store';

import rootActions from './actions';
import rootGetters from './getters';
import rootMutations from './mutations';

export default createStore({
  state: {
    allCustomers: [],
    saleOrderStates: [],
  },
  modules: {
    saleOrders: saleOrdersModule,
    salesHistory: saleHistoryModule,
    simulator: simulatorModule,
  },
  mutations: rootMutations,
  actions: rootActions,
  getters: rootGetters,
});
